import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase: test */
if (env === 'development' || env === 'test') {
	firebaseConfig = {
		apiKey: 'AIzaSyAwVir6AHz8aZGvtfb6hrmO2k6XmJSqgVM',
		authDomain: 'cgl-hunter-kids-test.firebaseapp.com',
		databaseURL: 'https://cgl-hunter-kids-test.firebaseio.com',
		projectId: 'cgl-hunter-kids-test',
		storageBucket: 'cgl-hunter-kids-test.appspot.com',
		messagingSenderId: '235311360842',
		appId: '1:235311360842:web:279565dc8940467645efb3'
	};
}

/* Connect to firebase: demo */
if (env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyCgwx2s3uN_NKABd4p6tSPfqslhMA6r9Jc',
		authDomain: 'cgl-hunter-kids-demo.firebaseapp.com',
		databaseURL: 'https://cgl-hunter-kids-demo.firebaseio.com',
		projectId: 'cgl-hunter-kids-demo',
		storageBucket: 'cgl-hunter-kids-demo.appspot.com',
		messagingSenderId: '915452279299',
		appId: '1:915452279299:web:097a523888b8c7012c6c36'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyAErolKhMGLsZtsEiaci7_CsXdUJyTIYMM',
		authDomain: 'cgl-hunter-kids-production.firebaseapp.com',
		databaseURL: 'https://cgl-hunter-kids-production.firebaseio.com',
		projectId: 'cgl-hunter-kids-production',
		storageBucket: 'cgl-hunter-kids-production.appspot.com',
		messagingSenderId: '1090869975736',
		appId: '1:1090869975736:web:24be51fa83be2a42b6724d',
		measurementId: 'G-5L6DF2WSGK'
	};
}

/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

/* Initialize analytics */
if (env === 'production') {
	firebase.analytics();
}


export default firebase.firestore().enablePersistence()
	.then(() => {
		console.log('Firestore persistence enabled!');
		return firebase.firestore();
	})
	.catch((error) => {
		if (error.code === 'failed-precondition') {
			console.error('Multiple tabs open, persistence can only be enabled in one tab at a time.');
		} else if (error.code === 'unimplemented') {
			console.error('The current browser does not support all of the features required to enable persistence');
		} else {
			console.error('Unknown persistence error: ', error);
		}
		return firebase.firestore();
	});