const imagesData = [
	'app-icon.png',
	'loading.gif',
	'background-1.jpg',
	'background-audio.svg',
	'background-book.svg',
	'background-forest.jpg',
	'background-forest2.jpg',
	'background-menu.png',
	'background-popup.png',
	'background-tracks.svg',
	'box-selected.svg',
	'box-story.svg',
	'box.svg',
	'chapter-nav-1-selected.svg',
	'chapter-nav-1.svg',
	'chapter-nav-2-selected.svg',
	'chapter-nav-2.svg',
	'chapter-nav-3-selected.svg',
	'chapter-nav-3.svg',
	'chapter-overview-1.svg',
	'chapter-overview-2.svg',
	'chapter-overview-3.svg',
	'character-ask.png',
	'character-liv.png',
	'characters-ask-and-liv.png',
	'characters-splash.png',
	'icon-arrow-back-red.svg',
	'icon-arrow-back-white.svg',
	'icon-arrow-down.svg',
	'icon-arrow-up.svg',
	'icon-book.svg',
	'icon-book2.png',
	'icon-chapter-1.svg',
	'icon-chapter-2.svg',
	'icon-chapter-3.svg',
	'icon-checkbox.svg',
	'icon-checkmark-grey.svg',
	'icon-checkmark.svg',
	'icon-circle.png',
	'icon-close.svg',
	'icon-download.svg',
	'icon-hand.svg',
	'icon-info.svg',
	'icon-loading.svg',
	'icon-menu-bullet.svg',
	'icon-pdf-white.svg',
	'icon-pdf.svg',
	'icon-print.svg',
	'icon-pwa-ios.svg',
	'icon-pwa-ios-add.svg',
	'icon-pwa-android.svg',
	'icon-zoom.svg',
	'logo-ask-og-liv.svg',
	'logo-blivnaturligvis.svg',
	'animations/shared/dust1.png',
	'animations/shared/dust2.png',
	'animations/shared/moonbeams.png',
	'animations/shared/moonbeams-blur1.png',
	'animations/shared/moonbeams-blur2.png',
	'animations/shared/moonbeams-blur3.png',
	'animations/shared/moonbeams-blur4.png',
	'animations/shared/moonbeams-blur5.png',
	'animations/shared/sunbeams1.png',
	'animations/shared/sunbeams2.png',
	'animations/shared/sunbeams1-blur1.png',
	'animations/shared/sunbeams1-blur2.png',
	'animations/shared/sunbeams1-blur3.png',
	'animations/shared/sunbeams1-blur4.png',
	'animations/shared/sunbeams1-blur5.png',
	'animations/shared/sunbeams2-blur1.png',
	'animations/shared/sunbeams2-blur2.png',
	'animations/shared/sunbeams2-blur3.png',
	'animations/shared/sunbeams2-blur4.png',
	'animations/shared/sunbeams2-blur5.png',
	'animations/build-huts/ask-eye.png',
	'animations/build-huts/ask-hand-1.png',
	'animations/build-huts/ask-hand-2.png',
	'animations/build-huts/ask-hand-3.png',
	'animations/build-huts/background.jpg',
	'animations/build-huts/bird.png',
	'animations/build-huts/bush.png',
	'animations/build-huts/hare-body.png',
	'animations/build-huts/hare-ear.png',
	'animations/build-huts/liv-eye.png',
	'animations/build-huts/thumb.jpg',
	'animations/draw-magic-symbols/amulet.png',
	'animations/draw-magic-symbols/ask-eyes.png',
	'animations/draw-magic-symbols/background.jpg',
	'animations/draw-magic-symbols/foreground.png',
	'animations/draw-magic-symbols/grass1.png',
	'animations/draw-magic-symbols/grass2.png',
	'animations/draw-magic-symbols/grass3.png',
	'animations/draw-magic-symbols/light1.png',
	'animations/draw-magic-symbols/light2.png',
	'animations/draw-magic-symbols/liv-arms1.png',
	'animations/draw-magic-symbols/liv-arms2.png',
	'animations/draw-magic-symbols/liv-arms3.png',
	'animations/draw-magic-symbols/liv-eye.png',
	'animations/draw-magic-symbols/swing.png',
	'animations/draw-magic-symbols/thumb.jpg',
	'animations/find-animal-tracks/background.jpg',
	'animations/find-animal-tracks/foot-left.png',
	'animations/find-animal-tracks/foot-right.png',
	'animations/find-animal-tracks/shadow.png',
	'animations/find-animal-tracks/thumb.jpg',
	'animations/make-a-weapon/ask-arm-1.png',
	'animations/make-a-weapon/ask-eyes.png',
	'animations/make-a-weapon/background.jpg',
	'animations/make-a-weapon/liv-eyes.png',
	'animations/make-a-weapon/thumb.jpg',
	'animations/move-like-a-hunter/arrow1.png',
	'animations/move-like-a-hunter/arrow2.png',
	'animations/move-like-a-hunter/arrow3.png',
	'animations/move-like-a-hunter/arrow4.png',
	'animations/move-like-a-hunter/background.jpg',
	'animations/move-like-a-hunter/deer-ear.png',
	'animations/move-like-a-hunter/deer-eyes.png',
	'animations/move-like-a-hunter/deer-nose.png',
	'animations/move-like-a-hunter/deer.png',
	'animations/move-like-a-hunter/foreground1.png',
	'animations/move-like-a-hunter/foreground2.png',
	'animations/move-like-a-hunter/liv.png',
	'animations/move-like-a-hunter/thumb.jpg',
	'animations/gather-magic/ask-eyes.png',
	'animations/gather-magic/background.jpg',
	'animations/gather-magic/bird1.png',
	'animations/gather-magic/bird2.png',
	'animations/gather-magic/foreground.png',
	'animations/gather-magic/grass1.png',
	'animations/gather-magic/grass2.png',
	'animations/gather-magic/grass3.png',
	'animations/gather-magic/hare-body.png',
	'animations/gather-magic/hare-ear1.png',
	'animations/gather-magic/hare-ear2.png',
	'animations/gather-magic/liv-eyes.png',
	'animations/gather-magic/thumb.jpg',
	'animations/light-a-fire/ask-and-liv-bright.png',
	'animations/light-a-fire/ask-and-liv-dark.png',
	'animations/light-a-fire/ask-and-liv.png',
	'animations/light-a-fire/background.jpg',
	'animations/light-a-fire/thumb.jpg',
	'animations/music-and-spirits/ask-eyes.png',
	'animations/music-and-spirits/background.jpg',
	'animations/music-and-spirits/branch.png',
	'animations/music-and-spirits/characters-bright.png',
	'animations/music-and-spirits/characters-dark.png',
	'animations/music-and-spirits/characters.png',
	'animations/music-and-spirits/fire.png',
	'animations/music-and-spirits/firestone.png',
	'animations/music-and-spirits/liv-eye.png',
	'animations/music-and-spirits/shaman-eyes.png',
	'animations/music-and-spirits/smoke-blur-1.png',
	'animations/music-and-spirits/smoke-blur-2.png',
	'animations/music-and-spirits/smoke-blur-3.png',
	'animations/music-and-spirits/thumb.jpg',
	'animations/taste-stoneage-food/ask-1.png',
	'animations/taste-stoneage-food/ask-2.png',
	'animations/taste-stoneage-food/ask-3.png',
	'animations/taste-stoneage-food/ask-eyes.png',
	'animations/taste-stoneage-food/background.jpg',
	'animations/taste-stoneage-food/liv-eye.png',
	'animations/taste-stoneage-food/rings.png',
	'animations/taste-stoneage-food/rings-1.png',
	'animations/taste-stoneage-food/rings-2.png',
	'animations/taste-stoneage-food/rings-3.png',
	'animations/taste-stoneage-food/thumb.jpg',
	'badges/magic-active.png',
	'badges/magic-inactive.png',
	'badges/fire-active.png',
	'badges/fire-inactive.png',
	'badges/prey-active.png',
	'badges/prey-inactive.png',
	'badges/animals-active.png',
	'badges/animals-inactive.png',
	'badges/cave-active.png',
	'badges/cave-inactive.png',
	'badges/hunting-active.png',
	'badges/hunting-inactive.png',
	'badges/gatherer-active.png',
	'badges/gatherer-inactive.png',
	'badges/tracks-active.png',
	'badges/tracks-inactive.png',
	'badges/weapons-active.png',
	'badges/weapons-inactive.png',
	'badges/rewards/amulet-glow.png',
	'badges/rewards/amulet.png',
	'badges/rewards/ask-prey.png',
	'badges/rewards/ask-weapons.png',
	'badges/rewards/ask.png',
	'badges/rewards/background.jpg',
	'badges/rewards/deer-glow.png',
	'badges/rewards/deer.png',
	'badges/rewards/fire-glow.png',
	'badges/rewards/fire.png',
	'badges/rewards/foreground.png',
	'badges/rewards/gatherer-glow.png',
	'badges/rewards/gatherer.png',
	'badges/rewards/hunting-glow.png',
	'badges/rewards/hunting.png',
	'badges/rewards/hut-glow.png',
	'badges/rewards/hut.png',
	'badges/rewards/liv-fire.png',
	'badges/rewards/liv.png',
	'badges/rewards/prey-glow.png',
	'badges/rewards/tracks-glow.png',
	'badges/rewards/tracks.png',
	'badges/rewards/weapons-glow.png',
	'badges/rewards/weapons-glow2.png',
	'badges/rewards/weapons.png',
	'illustrations/bevaeg-jer-som-jaegere.png',
	'illustrations/blade-og-traeer.png',
	'illustrations/bliv-klog-paa-dyrespor.png',
	'illustrations/byg-huler.png',
	'illustrations/find-dyrespor.png',
	'illustrations/form-en-lerskaal.png',
	'illustrations/jaegertraening.png',
	'illustrations/lav-en-pung.png',
	'illustrations/lav-et-vaaben.png',
	'illustrations/lav-jaeger-stenaldermad.png',
	'illustrations/lav-stenalderdragter.png',
	'illustrations/lav-stenalderkunst.png',
	'illustrations/lav-tegnekul.png',
	'illustrations/musik-og-aandemaneri.png',
	'illustrations/saml-magi.png',
	'illustrations/smag-paa-stenalderkost.png',
	'illustrations/snit-en-brummer.png',
	'illustrations/spiselige-planter.png',
	'illustrations/taand-baal.png',
	'illustrations/tegn-dyrespor.png',
	'illustrations/tegn-magiske-tegn.png',
	'illustrations/vendespil-med-dyr.png',
	'plants/plant1.svg',
	'plants/plant2.svg',
	'plants/plant3.svg',
	'plants/plant4.svg',
	'plants/plant5.svg',
	'plants/plant6.svg',
	'plants/plant7.svg',
	'plants/plant8.svg',
	'plants/plant9.svg',
	'plants/plant10.svg',
	'plants/plant11.svg',
	// 'thumbs/bliv-klog-paa-dyrespor.jpg',
	'thumbs/dyr.jpg',
	// 'thumbs/dyrefodspor.jpg',
	'thumbs/farvelaegning-ask-og-liv.jpg',
	'thumbs/farvelaegning-maerker.jpg',
	// 'thumbs/min-dyrebog.jpg',
	// 'thumbs/naturens-groenne-spisekammer.jpg',
	// 'thumbs/opskrifter.jpg',
	'thumbs/puslespil.jpg',
	// 'thumbs/tegn.jpg',
	'thumbs/traeer-og-buske.jpg',
	// 'thumbs/vendespil.jpg',
];

export default imagesData;
