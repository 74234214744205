import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import Button from 'components/button/button';
import CookieConsentController from 'components/cookie-consent/cookie-consent-controller';
import './landing-page.scss';

const LandingPage = ({handleShowPWAPopup, selectPage, resetCache}) => {
	return (
		<div className="LandingPage">
			<div className="LandingPage-plant LandingPage-plant--topRight" />
			<div className="LandingPage-plant LandingPage-plant--topMiddleLeft" />
			<div className="LandingPage-plant LandingPage-plant--topMiddleRight" />
			<div className="LandingPage-plant LandingPage-plant--rightMiddle" />
			<div className="LandingPage-plant LandingPage-plant--leftTopTop" />
			<div className="LandingPage-plant LandingPage-plant--leftTopBottom" />
			<div className="LandingPage-plant LandingPage-plant--leftBottom" />
			<div className="LandingPage-plant LandingPage-plant--bottomLeft" />
			<div className="LandingPage-plant LandingPage-plant--bottomRight" />
			<div className="LandingPage-characters" onClick={() => {selectPage('chapterOverview');}} />
			<CookieConsentController handleShowPWAPopup={handleShowPWAPopup} />
			{(appConfig.env === 'development' || appConfig.env === 'test') &&
				<Button class="reset" text="Reset" onClick={() => {resetCache();}} />}
			<div className="LandingPage-BNV-logo"></div>
			<div className="LandingPage-Nordea-logo"></div>
		</div>
	);
};

LandingPage.propTypes = {
	handleShowPWAPopup: PropTypes.func.isRequired,
	selectPage: PropTypes.func.isRequired,
	resetCache: PropTypes.func.isRequired
};

export default LandingPage;