import React from 'react';
import firestore from 'firestore';
import PopupController from 'components/popup/popup-controller';
import GameController from 'components/game/game-controller';
import './app.scss';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isInStandaloneMode: false,
		};
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		/* Initialize firestore */
		firestore.then(() => {console.log('Firestore initialized');});

		/* Device info */
		let isInStandaloneMode = 
			window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches;	

		/* Finish loading app */
		this.setState({isInStandaloneMode, isLoading: false});
	}

	/**
	 * Render component
	 */
	render() {
		/* Loading screen */
		if (this.state.isLoading) {
			return <div id="app" className="App"><div>Loading ...</div></div>;
		}

		/* Game */
		return (
			<div id="app" className={'App'}>
				<PopupController>
					<GameController isInStandaloneMode={this.state.isInStandaloneMode} />
				</PopupController>
			</div>
		);
	}
}

export default App;
