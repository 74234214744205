import React from 'react';
import PropTypes from 'prop-types';
import BackButton from 'components/layout/back-button';
import {renderMarkdown} from 'helpers/text-helper';
import './introduction.scss';

const Introduction = ({type, selectPage}) => {
	/* Load introduction text */
	let introText = null;
	if (type === 'page') {
		try {
			introText = require('data/pages/introduction.md');
		} catch (e) {
			console.error('Could not find text: data/pages/introduction.md');
		}
	} else {
		try {
			introText = require('data/pages/introduction-popup.md');
		} catch (e) {
			console.error('Could not find text: data/pages/introduction-popup.md');
		}
	}


	return (
		<div className={'Introduction Introduction--' + type}>
			{type === 'page' && <BackButton color = "red" onClick = {() => {selectPage('chapterOverview');}}/>}
			<div className="Introduction-content">
				<div className="Introduction-text">
					{introText && renderMarkdown(introText.default)}
				</div>
			</div>
		</div>
	);
};

Introduction.defaultProps = {
	type: 'page'
};

Introduction.propTypes = {
	type: PropTypes.string,
	selectPage: PropTypes.func,
};

export default Introduction;