/**
 * Get OS and Browser name
 */
export function getOSAndBrowserInfo() {
	let os = null, browser = null;
	const {detect} = require('detect-browser');
	const browserInfo = detect();
	if (browserInfo && browserInfo.os) os = browserInfo.os;
	if (browserInfo && browserInfo.name) browser = browserInfo.name;
	return [os, browser];
}

/**
 * Determine if device is mobile or tablet
 * https://medium.com/simplejs/detect-the-users-device-type-with-a-simple-javascript-check-4fc656b735e1
 */
export function isPhoneOrTablet() {
	var isPhoneOrTablet = false;
	((a) => {
		// eslint-disable-next-line max-len, no-useless-escape
		if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
			isPhoneOrTablet = true;
		}
	}) (navigator.userAgent || navigator.vendor || window.opera);

	/* Special case for iPad & iPhone */
	const platform = window.navigator.platform.toLowerCase();
	if (/macintel/.test(platform) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) isPhoneOrTablet = true; 

	return isPhoneOrTablet;
}


/**
 * Get Android Version
 */
export function getAndroidVersion() {
	let ua = navigator.userAgent.toLowerCase();
	let match = ua.match(/android\s([0-9.]*)/i);
	if (match) {
		return parseFloat(match[1]);
	}
	return 0;
}

/**
 * Check if iOS device is an too old model
 * https://51degrees.com/blog/device-detection-for-apple-iphone-and-ipad
 * https://stackoverflow.com/questions/8309998/how-to-determine-which-iphone-version-the-javascript-code-runs-on
 * https://everyi.com/by-capability/maximum-supported-ios-version-for-ipod-iphone-ipad.html
 */
export function isOldIOSDevice() {
	/* Create a canvas element which can be used to retrieve information about the GPU. */
	let renderer = null;
	let canvas = document.createElement('canvas');
	if (canvas) {
		let context = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
		if (context) {
			let info = context.getExtension('WEBGL_debug_renderer_info');
			if (info) {
				renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL);
			}
		}
	}	

	if (renderer) {
		if (window.screen.height / window.screen.width === 1024 / 768) {
			/* iPad, iPad 2, iPad Mini (max iOS version is 5.1.1 / 9.3.5 / 9.3.5) */
			if (window.devicePixelRatio === 1) return true;

			/* iPad 3 (max iOS version is 9.3.5) */
			if (renderer === 'PowerVR SGX 543') return true;
			/* iPad 4 (max iOS version is 10.3.3) */
			if (renderer === 'PowerVR SGX 554') return true;
		}

		/* iPhone 1/3G/3GS or iPod 1/2/3 (max iOS version is 3.1.3/4.2/6.1.6 or 3.1.3/4.2.1/5.1.1) */
		if ((window.screen.height / window.screen.width === 1.5) && (window.devicePixelRatio === 1)) {
			return true;
		}

		/* iPhone 4/4s or iPod 4 (max iOS version is 7.1.2/9.3.5 or 6.1.6) */
		if ((window.screen.height / window.screen.width === 1.5) && (window.devicePixelRatio === 2)) {
			return true;
		}
		
		if ((window.screen.height / window.screen.width === 1.775) && (window.devicePixelRatio === 2)) {
			/* iPhone 5/5C or iPod 5 (max iOS version is 10.3.3 or 9.3.5) */
			if (renderer === 'PowerVR SGX 543') return true;
		}
	}

	return false;
}
