const cookieUiTexts = {
	popUpText: 'Spillet bruger cookies til at gemme dine valg. Ved at trykke ‘Forstået’ accepterer du spillets brug af cookies.',
	acceptBtn: 'Forstået'
};

const generalUiTexts = {
	part: 'Del',
	chapter: 'kapitel',
	linkIntroduction: 'Introduktion',
	linkStory: 'Historien',
	linkResources: 'Materialer',
	linkAboutGame: 'Om spillet',
	materiallist: 'Materialeliste',
	before: 'Hjemme',
	during: 'Ude i naturen',
	after: 'Hjemme igen',
	badges: 'Mærker',
	noActivitiesSelected2: 'Du har ikke valgt nogen aktiviteter.',
	noActivitiesSelected: 'Du har ikke valgt nogen aktiviteter i denne del',
	activityParts: {
		talkAbout: 'Snak om',
		illustrations: 'Tegninger',
		facts: 'Fakta',
		focusWords: 'Fokusord',
		materials: 'Materialer'
	},
	printTitle: 'Ask & Liv'
};

const popUpUiTexts = {
	activity: 'Aktivitet',
	story: 'Historie',
	readStory: 'Læs historie',
	openActivity: 'Åbn aktiviteten',
	addToPlan: 'Vælg',
	printActivity: 'Print denne aktivitet',
	printPlan: 'Print alle valgte aktiviteter',
	printStory: 'Print dette kapitel',
	printStories: 'Print hele historien',
	planEmail: 'Send valgte aktiviteter i en mail:',
	storyEmail: 'Send hele historien i en mail:',
	placeholderInput: 'Indtast email',
	sendBtn: 'Send'
}

const emailUiTexts = {
	emailSent: 'Mailen blev sendt!',
	emailError: 'Noget gik galt. Tjek om adressen er rigtig.',
};

const fetchUiTexts = {
	fetchError: 'Noget gik galt. Tjek om du har forbindelse til internettet.'
}

export{
	cookieUiTexts,
	generalUiTexts,
	popUpUiTexts,
	emailUiTexts,
	fetchUiTexts
};