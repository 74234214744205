import React from 'react';
import PropTypes from 'prop-types';
import {getImageSize, getXPosition, getYPosition} from 'helpers/animation-helper';
import DustParticles from './dust-particles/dust-particles';
import './draw-magic-symbols.scss';

const DrawMagicSymbols = ({limitAnimations, containerWidth, containerHeight}) => {

	let grassOrigin1 = [20.9, 95.1];
	let grassOrigin2 = [27.2, 94.9];
	let grassOrigin3 = [24.7, 92.1];

	if (containerWidth > 0 && containerHeight > 0) {
		let imageRatio = 1024. / 590.;

		let [imageWidth, imageHeight] = getImageSize(containerWidth, containerHeight, imageRatio);
		if (imageWidth > containerWidth) {
			grassOrigin1[0] = getXPosition(containerWidth, imageWidth, 'left', grassOrigin1[0]);
			grassOrigin2[0] = getXPosition(containerWidth, imageWidth, 'left', grassOrigin2[0]);
			grassOrigin3[0] = getXPosition(containerWidth, imageWidth, 'left', grassOrigin3[0]);
		}
		if (imageHeight > containerHeight) {
			grassOrigin1[1] = getYPosition(containerHeight, imageHeight, 'bottom', grassOrigin1[1]);
			grassOrigin2[1] = getYPosition(containerHeight, imageHeight, 'bottom', grassOrigin2[1]);
			grassOrigin3[1] = getYPosition(containerHeight, imageHeight, 'bottom', grassOrigin3[1]);
		}
	}
	
	return (
		<div className="DrawMagicSymbols">	

			{/* Moon beams */}
			{!limitAnimations && <React.Fragment>
				<div className="DrawMagicSymbols-moonbeam DrawMagicSymbols-moonbeam--1" />
				<div className="DrawMagicSymbols-moonbeam DrawMagicSymbols-moonbeam--2" />
				<div className="DrawMagicSymbols-moonbeam DrawMagicSymbols-moonbeam--3" />
				<div className="DrawMagicSymbols-moonbeam DrawMagicSymbols-moonbeam--4" />
				<div className="DrawMagicSymbols-moonbeam DrawMagicSymbols-moonbeam--5" />
				<div className="DrawMagicSymbols-moonbeam DrawMagicSymbols-moonbeam--6" />
			</React.Fragment>}

			{/* Dust */}
			{!limitAnimations && <DustParticles />}

			{/* Foreground & amulet */}
			<div className="DrawMagicSymbols-foreground" />
			<div className="DrawMagicSymbols-amulet" />

			{/* Character eyes */}
			<div className="DrawMagicSymbols-askEyes" />
			<div className="DrawMagicSymbols-livEyes" />
			
			{/* Liv praying */}
			<div className="DrawMagicSymbols-livArms DrawMagicSymbols-livArms--1" />
			<div className="DrawMagicSymbols-livArms DrawMagicSymbols-livArms--2" />
			<div className="DrawMagicSymbols-livArms DrawMagicSymbols-livArms--3" />
			<div className="DrawMagicSymbols-livEyes2" />
			
			{/* Ask swinging */}
			<div className="DrawMagicSymbols-swing" />

			{/* Grass */}			
			<div 
				className="DrawMagicSymbols-grass1" 
				style={{
					WebkitTransformOrigin: grassOrigin1[0] + '% ' + grassOrigin1[1] + '%',
					transformOrigin: grassOrigin1[0] + '% ' + grassOrigin1[1] + '%'
				}}
			/>
			<div 
				className="DrawMagicSymbols-grass2" 
				style={{
					WebmitTransformOrigin: grassOrigin2[0] + '% ' + grassOrigin2[1] + '%',
					transformOrigin: grassOrigin2[0] + '% ' + grassOrigin2[1] + '%'
				}}
			/>
			<div 
				className="DrawMagicSymbols-grass3" 
				style={{
					WebkitTransformOrigin: grassOrigin3[0] + '% ' + grassOrigin3[1] + '%',
					transformOrigin: grassOrigin3[0] + '% ' + grassOrigin3[1] + '%'
				}}
			/>
		
		</div>
	);
};

DrawMagicSymbols.propTypes = {
	limitAnimations: PropTypes.bool.isRequired,
	containerWidth: PropTypes.number.isRequired,
	containerHeight: PropTypes.number.isRequired
};

export default DrawMagicSymbols;