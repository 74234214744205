import React from 'react';
import PropTypes from 'prop-types';
import {getImageSize, getXPosition, getYPosition} from 'helpers/animation-helper';
import './gather-magic.scss';

const GatherMagic = ({limitAnimations, containerWidth, containerHeight}) => {
	let hareEar1 = [76.7, 7.8];
	let hareEar2 = [77, 7.6];
	let bird1 = [41.2, 14.9];
	let bird2 = [92.2, 69.];
	let grassOrigin1 = [20.9, 95.1];
	let grassOrigin2 = [27.2, 94.9];
	let grassOrigin3 = [24.7, 92.1];

	if (containerWidth > 0 && containerHeight > 0) {
		let imageRatio = 1024. / 590.;

		let [imageWidth, imageHeight] = getImageSize(containerWidth, containerHeight, imageRatio);
		if (imageWidth > containerWidth) {
			hareEar1[0] = getXPosition(containerWidth, imageWidth, 'right', hareEar1[0]);
			hareEar2[0] = getXPosition(containerWidth, imageWidth, 'right', hareEar2[0]);
			bird1[0] = getXPosition(containerWidth, imageWidth, 'right', bird1[0]);
			bird2[0] = getXPosition(containerWidth, imageWidth, 'right', bird2[0]);
			grassOrigin1[0] = getXPosition(containerWidth, imageWidth, 'left', grassOrigin1[0]);
			grassOrigin2[0] = getXPosition(containerWidth, imageWidth, 'left', grassOrigin2[0]);
			grassOrigin3[0] = getXPosition(containerWidth, imageWidth, 'left', grassOrigin3[0]);
		}
		if (imageHeight > containerHeight) {
			hareEar1[1] = getYPosition(containerHeight, imageHeight, 'top', hareEar1[1]);
			hareEar2[1] = getYPosition(containerHeight, imageHeight, 'top', hareEar2[1]);
			bird1[1] = getYPosition(containerHeight, imageHeight, 'top', bird1[1]);
			bird2[1] = getYPosition(containerHeight, imageHeight, 'top', bird2[1]);
			grassOrigin1[1] = getYPosition(containerHeight, imageHeight, 'bottom', grassOrigin1[1]);
			grassOrigin2[1] = getYPosition(containerHeight, imageHeight, 'bottom', grassOrigin2[1]);
			grassOrigin3[1] = getYPosition(containerHeight, imageHeight, 'bottom', grassOrigin3[1]);
		}
	}

	return (
		<div className="GatherMagic">

			{/* Hare */}
			<div className="GatherMagic-hare">
				<div 
					className="GatherMagic-hare-ear1" 
					style={{
						WebkitTransformOrigin: hareEar1[0] + '% ' + hareEar1[1] + '%',
						transformOrigin: hareEar1[0] + '% ' + hareEar1[1] + '%'
					}}
				/>
				<div 
					className="GatherMagic-hare-ear2" 
					style={{
						WebkitTransformOrigin: hareEar2[0] + '% ' + hareEar2[1] + '%',
						transformOrigin: hareEar2[0] + '% ' + hareEar2[1] + '%'
					}}  
				/>
			</div>
			
			{/* Birds */}
			<div 
				className="GatherMagic-bird1" 
				style={{
					WebkitTransformOrigin: bird1[0] + '% ' + bird1[1] + '%',
					transformOrigin: bird1[0] + '% ' + bird1[1] + '%'
				}}
			/>
			<div 
				className="GatherMagic-bird2" 
				style={{
					WebkitTransformOrigin: bird2[0] + '% ' + bird2[1] + '%',
					transformOrigin: bird2[0] + '% ' + bird2[1] + '%'
				}}
			/>

			{/* Ask's and Liv's eyes */}
			<div className="GatherMagic-askEyes" />
			<div className="GatherMagic-livEyes" />

			{/* Grass */}
			<div 
				className="GatherMagic-grass1" 
				style={{
					WebkitTransformOrigin: grassOrigin1[0] + '% ' + grassOrigin1[1] + '%',
					transformOrigin: grassOrigin1[0] + '% ' + grassOrigin1[1] + '%'
				}}
			/>
			<div 
				className="GatherMagic-grass2" 
				style={{
					WebkitTransformOrigin: grassOrigin2[0] + '% ' + grassOrigin2[1] + '%',
					transformOrigin: grassOrigin2[0] + '% ' + grassOrigin2[1] + '%'
				}}
			/>
			<div 
				className="GatherMagic-grass3" 
				style={{
					WebkitTransformOrigin: grassOrigin3[0] + '% ' + grassOrigin3[1] + '%',
					transformOrigin: grassOrigin3[0] + '% ' + grassOrigin3[1] + '%'
				}}
			/>

			{/* Foreground */}
			<div className="GatherMagic-foreground" />

			{/* Sun beams */}
			{!limitAnimations && <React.Fragment>
				<div className="GatherMagic-sunbeam GatherMagic-sunbeam--1" />
				<div className="GatherMagic-sunbeam GatherMagic-sunbeam--2" />
				<div className="GatherMagic-sunbeam GatherMagic-sunbeam--3" />
				<div className="GatherMagic-sunbeam GatherMagic-sunbeam--4" />
				<div className="GatherMagic-sunbeam GatherMagic-sunbeam--5" />
				<div className="GatherMagic-sunbeam GatherMagic-sunbeam--6" />
			</React.Fragment>}
		</div>
	);
};

GatherMagic.propTypes = {
	limitAnimations: PropTypes.bool.isRequired,
	containerWidth: PropTypes.number.isRequired,
	containerHeight: PropTypes.number.isRequired
};

export default GatherMagic;