import React from 'react';
import PropTypes from 'prop-types';
import imagesData from 'data/images-data';
import './image-loader.scss';

const ImageLoader = ({handlePreloadImage}) => {
	return (
		<div className="ImageLoader">
			{imagesData.map((fileName, index) => {
				let imgSrc = 'this-file-does-not-exist';
				try {
					imgSrc = require('assets/images/' + fileName);
				} catch (e) {
					console.error('Could not find image: assets/images/' + fileName);
				}
				return (
					<img 
						key={index}
						src={imgSrc}
						alt="preload"
						onLoad={() => {handlePreloadImage('ok');}}
						onError={() => {handlePreloadImage('error');}}
					/>
				);				
			})}			
		</div>
	);
};

ImageLoader.propTypes = {
	handlePreloadImage: PropTypes.func.isRequired,
};

export default ImageLoader;