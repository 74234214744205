import React from 'react';
import PropTypes from 'prop-types';
import './zoom-button.scss';

const ZoomButton = ({onClick}) => {
	return (
		<div className="ZoomButton" onClick={onClick ? onClick : null}>
			<div className="ZoomButton-icon" />
		</div>
	);
};



ZoomButton.propTypes = {
	onClick: PropTypes.func
};

export default ZoomButton;