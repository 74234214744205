import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';

const PrintComponent = ({content}) => {
	if (content) {
		return (
			<div>
				{renderMarkdown(content)}

			</div>
		);
	}
	return <div><p>{generalUiTexts.noActivitiesSelected2}</p></div>;
};

PrintComponent.propTypes = {
	content: PropTypes.string
};

export default PrintComponent;