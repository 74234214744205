import React from 'react';
import PropTypes from 'prop-types';
import {getImageSize, getXPosition, getYPosition} from 'helpers/animation-helper';
import './music-and-spirits.scss';

const MusicAndSpirits = ({containerWidth, containerHeight}) => {

	let branch = [64.5, 6.0];
	let fireeffects = [55.4, 77.9];
	let fontSizeScale = 1;

	if (containerWidth > 0 && containerHeight > 0) {
		let imageRatio = 1024. / 768.;

		let [imageWidth, imageHeight] = getImageSize(containerWidth, containerHeight, imageRatio);
		fontSizeScale = (imageWidth / containerWidth);
		if (imageWidth > containerWidth) {
			branch[0] = getXPosition(containerWidth, imageWidth, 'center', branch[0]);
			fireeffects[0] = getXPosition(containerWidth, imageWidth, 'center', fireeffects[0]);

		}
		if (imageHeight > containerHeight) {
			branch[1] = getYPosition(containerHeight, imageHeight, 'center', branch[1]);
			fireeffects[1] = getYPosition(containerHeight, imageHeight, 'center', fireeffects[1]);
		}
	}

	return (
		<div className="MusicAndSpirits">
			{/* Branch */}
			<div 
				className="MusicAndSpirits-branch" 
				style={{
					WebkitTransformOrigin: branch[0] + '% ' + branch[1] + '%',
					transformOrigin: branch[0] + '% ' + branch[1] + '%'
				}}
			/>

			{/* Character glows */}
			<div className="MusicAndSpirits-characters" />
			<div className="MusicAndSpirits-characters MusicAndSpirits-characters--dark" />
			<div className="MusicAndSpirits-characters MusicAndSpirits-characters--bright" />

			{/* Character eyes */}
			<div className="MusicAndSpirits-shamanEyes" />
			<div className="MusicAndSpirits-askEyes" />
			<div className="MusicAndSpirits-livEye" />

			{/* Smoke */}
			<div className="MusicAndSpirits-smoke MusicAndSpirits-smoke--1" />
			<div className="MusicAndSpirits-smoke MusicAndSpirits-smoke--2" />
			<div className="MusicAndSpirits-smoke MusicAndSpirits-smoke--3" />

			{/* Fire */}
			<div className="MusicAndSpirits-fire" />
			<div 
				className="MusicAndSpirits-fireeffects" 
				style={{fontSize: fontSizeScale + 'em', left: fireeffects[0] + '%', top: fireeffects[1] + '%'}}
			>
				<div className="MusicAndSpirits-glow MusicAndSpirits-glow--1" />
				<div className="MusicAndSpirits-glow MusicAndSpirits-glow--2" />
				<div className="MusicAndSpirits-spark MusicAndSpirits-spark--1" />
				<div className="MusicAndSpirits-spark MusicAndSpirits-spark--2" />
				<div className="MusicAndSpirits-spark MusicAndSpirits-spark--3" />
				<div className="MusicAndSpirits-spark MusicAndSpirits-spark--4" />
				<div className="MusicAndSpirits-spark MusicAndSpirits-spark--5" />
			</div>
			<div className="MusicAndSpirits-firestone" />
		</div>
	);
};

MusicAndSpirits.propTypes = {
	containerWidth: PropTypes.number.isRequired,
	containerHeight: PropTypes.number.isRequired
};

export default MusicAndSpirits;