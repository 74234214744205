import React from 'react';
import PropTypes from 'prop-types';
import './print-button.scss';

const PrintButton = ({page, onClick}) => {
	return (
		<div 
			className={'PrintButton PrintButton--' + page} 
			onClick={onClick ? onClick : null}
		>
			<div className="PrintButton-icon" />
			<div className="PrintButton-text">Print</div>
		</div>
	);
};

PrintButton.defaultProptypes = {
	page: 'materialList',
};

PrintButton.propTypes = {
	page: PropTypes.string,
	onClick: PropTypes.func
};

export default PrintButton;