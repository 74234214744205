let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let apiURL = 'http://localhost:3001/api/';
if (env === 'test') {
	apiURL = 'https://cgl-hunter-kids-test.web.app/api/';
}

if (env === 'demo') {
	apiURL = 'https://cgl-hunter-kids-demo.web.app/api/';
}

if (env === 'production') {
	apiURL = 'https://cgl-hunter-kids-production.web.app/api/';
}

const appConfig = {
	env: env,
	name: 'hunter-kids',
	apiURL: apiURL,
	cookieConsentEndpoint: '/hunterkidsCookieConsent',
	cookieConsentName: 'cgl_hunterkids_cookies',
	gameProgressEndpoint: '/hunterkidsGameData',
	cookieGameProgressName: 'cgl_hunterkids_gameprogress',
	adminFullAccess: ['diana@cphgamelab.dk', 'it@cphgamelab.dk']
};

export default appConfig;