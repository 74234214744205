const badgesData = [
	{
		id: 'cave',
		name: 'Hule'
	},
	{
		id: 'fire',
		name: 'Bål'
	},
	{
		id: 'tracks',
		name: 'Spor'
	},
	{
		id: 'animals',
		name: 'Hjort'
	},
	{
		id: 'magic',
		name: 'Amulet'
	},
	{
		id: 'prey',
		name: 'Bytte'
	},
	{
		id: 'weapons',
		name: 'Våben'
	},
	{
		id: 'gatherer',
		name: 'Pung'
	},
	{
		id: 'hunting',
		name: 'Jagt'
	}
];

export default badgesData;