import React from 'react';
import PropTypes from 'prop-types';
import {getImageSize, getXPosition, getYPosition} from 'helpers/animation-helper';
import './make-a-weapon.scss';

const MakeAWeapon = ({containerWidth, containerHeight}) => {
	let askArmOrigin = [61.8, 56.2];
	let dewPos1 = [11.7, 46.1];
	let dewPos2 = [16.2, 44.9];
	let dewPos3 = [19.4, 55.8];
	let dewPos4 = [21.9, 53.8];
	let dewPos5 = [25.7, 51.7];
	let dewPos6 = [65.6, 3.2];
	let dewPos7 = [74.1, 4.5];
	let dewPos8 = [79.1, 6.6];
	let dewPos9 = [81.6, 95.5];
	let dewPos10 = [89.1, 86.0];
	

	if (containerWidth > 0 && containerHeight > 0) {
		let imageRatio = 1024. / 768.;

		let [imageWidth, imageHeight] = getImageSize(containerWidth, containerHeight, imageRatio);
		if (imageWidth > containerWidth) {
			askArmOrigin[0] = getXPosition(containerWidth, imageWidth, 'center', askArmOrigin[0]);
			dewPos1[0] = getXPosition(containerWidth, imageWidth, 'center', dewPos1[0]);
			dewPos2[0] = getXPosition(containerWidth, imageWidth, 'center', dewPos2[0]);
			dewPos3[0] = getXPosition(containerWidth, imageWidth, 'center', dewPos3[0]);
			dewPos4[0] = getXPosition(containerWidth, imageWidth, 'center', dewPos4[0]);
			dewPos5[0] = getXPosition(containerWidth, imageWidth, 'center', dewPos5[0]);
			dewPos6[0] = getXPosition(containerWidth, imageWidth, 'center', dewPos6[0]);
			dewPos7[0] = getXPosition(containerWidth, imageWidth, 'center', dewPos7[0]);
			dewPos8[0] = getXPosition(containerWidth, imageWidth, 'center', dewPos8[0]);
			dewPos9[0] = getXPosition(containerWidth, imageWidth, 'center', dewPos9[0]);
			dewPos10[0] = getXPosition(containerWidth, imageWidth, 'center', dewPos10[0]);
			
		}
		if (imageHeight > containerHeight) {
			askArmOrigin[1] = getYPosition(containerHeight, imageHeight, 'bottom', askArmOrigin[1]);
			dewPos1[1] = getYPosition(containerHeight, imageHeight, 'bottom', dewPos1[1]);
			dewPos2[1] = getYPosition(containerHeight, imageHeight, 'bottom', dewPos2[1]);
			dewPos3[1] = getYPosition(containerHeight, imageHeight, 'bottom', dewPos3[1]);
			dewPos4[1] = getYPosition(containerHeight, imageHeight, 'bottom', dewPos4[1]);
			dewPos5[1] = getYPosition(containerHeight, imageHeight, 'bottom', dewPos5[1]);
			dewPos6[1] = getYPosition(containerHeight, imageHeight, 'bottom', dewPos6[1]);
			dewPos7[1] = getYPosition(containerHeight, imageHeight, 'bottom', dewPos7[1]);
			dewPos8[1] = getYPosition(containerHeight, imageHeight, 'bottom', dewPos8[1]);
			dewPos9[1] = getYPosition(containerHeight, imageHeight, 'bottom', dewPos9[1]);
			dewPos10[1] = getYPosition(containerHeight, imageHeight, 'bottom', dewPos10[1]);
		}
	}


	return (
		<div className="MakeAWeapon">
			
			{/* Ask's arm */}
			<div 
				className="MakeAWeapon-askArm" 
				style={{
					WebkitTransformOrigin: askArmOrigin[0] + '% ' + askArmOrigin[1] + '%',
					transformOrigin: askArmOrigin[0] + '% ' + askArmOrigin[1] + '%'
				}} 
			/>
			
			{/* Aks's and Liv's eyes */}
			<div className="MakeAWeapon-askEyes" />
			<div className="MakeAWeapon-livEyes" />

			{/* Dew drops */}
			<div 
				className="MakeAWeapon-dew MakeAWeapon-dew--1" 
				style={{left: dewPos1[0] + '%', top: dewPos1[1] + '%'}}		
			/>
			<div 
				className="MakeAWeapon-dew MakeAWeapon-dew--2" 
				style={{left: dewPos2[0] + '%', top: dewPos2[1] + '%'}}
			/>
			<div 
				className="MakeAWeapon-dew MakeAWeapon-dew--3" 
				style={{left: dewPos3[0] + '%', top: dewPos3[1] + '%'}}
			/>
			<div 
				className="MakeAWeapon-dew MakeAWeapon-dew--4" 
				style={{left: dewPos4[0] + '%', top: dewPos4[1] + '%'}}
			/>
			<div 
				className="MakeAWeapon-dew MakeAWeapon-dew--5"
				style={{left: dewPos5[0] + '%', top: dewPos5[1] + '%'}} 
			/>
			<div 
				className="MakeAWeapon-dew MakeAWeapon-dew--6" 
				style={{left: dewPos6[0] + '%', top: dewPos6[1] + '%'}}
			/>
			<div 
				className="MakeAWeapon-dew MakeAWeapon-dew--7" 
				style={{left: dewPos7[0] + '%', top: dewPos7[1] + '%'}}
			/>
			<div 
				className="MakeAWeapon-dew MakeAWeapon-dew--8" 
				style={{left: dewPos8[0] + '%', top: dewPos8[1] + '%'}}
			/>
			<div 
				className="MakeAWeapon-dew MakeAWeapon-dew--9" 
				style={{left: dewPos9[0] + '%', top: dewPos9[1] + '%'}}
			/>
			<div 
				className="MakeAWeapon-dew MakeAWeapon-dew--10" 
				style={{left: dewPos10[0] + '%', top: dewPos10[1] + '%'}}
			/>
		</div>
	);
};


MakeAWeapon.propTypes = {
	containerWidth: PropTypes.number.isRequired,
	containerHeight: PropTypes.number.isRequired
};


export default MakeAWeapon;