import React from 'react';
import DustParticles from './dust-particles/dust-particles';
import PropTypes from 'prop-types';
import {getImageSize, getXPosition, getYPosition} from 'helpers/animation-helper';
import './move-like-a-hunter.scss';

const MoveLikeAHunter = ({limitAnimations, containerWidth, containerHeight}) => {
	let dearEarOrigin = [50.8, 11.3];

	if (containerWidth > 0 && containerHeight > 0) {
		let imageRatio = 1024. / 626.;

		let [imageWidth, imageHeight] = getImageSize(containerWidth, containerHeight, imageRatio);
		if (imageWidth > containerWidth) {
			dearEarOrigin[0] = getXPosition(containerWidth, imageWidth, 'center', dearEarOrigin[0]);
		}
		if (imageHeight > containerHeight) {
			dearEarOrigin[1] = getYPosition(containerHeight, imageHeight, 'top', dearEarOrigin[1]);
		}
	}

	return (
		<div className="MoveLikeAHunter">

			{/* Deer */}
			<div className="MoveLikeAHunter-deer" />
			<div 
				className="MoveLikeAHunter-deerEar"
				style={{
					WebkitTransformOrigin: dearEarOrigin[0] + '% ' + dearEarOrigin[1] + '%',
					transformOrigin: dearEarOrigin[0] + '% ' + dearEarOrigin[1] + '%'
				}} 
			/>
			<div className="MoveLikeAHunter-deerNose" />
			<div className="MoveLikeAHunter-deerEyes" />

			{/* Dust */}
			{!limitAnimations && <DustParticles />}

			{/* Sun beams */}
			{!limitAnimations && <React.Fragment>
				<div className="MoveLikeAHunter-sunbeam MoveLikeAHunter-sunbeam--1" />
				<div className="MoveLikeAHunter-sunbeam MoveLikeAHunter-sunbeam--2" />
				<div className="MoveLikeAHunter-sunbeam MoveLikeAHunter-sunbeam--3" />
				<div className="MoveLikeAHunter-sunbeam MoveLikeAHunter-sunbeam--4" />
				<div className="MoveLikeAHunter-sunbeam MoveLikeAHunter-sunbeam--5" />
				<div className="MoveLikeAHunter-sunbeam MoveLikeAHunter-sunbeam--6" />
			</React.Fragment>}

			{/* Foreground */}
			<div className="MoveLikeAHunter-foreground1" />
			<div className="MoveLikeAHunter-foreground2" />

			{/* Liv & arrow */}
			<div className="MoveLikeAHunter-arrow MoveLikeAHunter-arrow--1" />
			<div className="MoveLikeAHunter-arrow MoveLikeAHunter-arrow--2" />
			<div className="MoveLikeAHunter-arrow MoveLikeAHunter-arrow--3" />
			<div className="MoveLikeAHunter-arrow MoveLikeAHunter-arrow--4" />
			<div className="MoveLikeAHunter-liv" />
		</div>
	);
};

MoveLikeAHunter.propTypes = {
	limitAnimations: PropTypes.bool.isRequired,
	containerWidth: PropTypes.number.isRequired,
	containerHeight: PropTypes.number.isRequired
};

export default MoveLikeAHunter;