import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button/button';
import {cookieUiTexts} from 'data/ui-texts';
import './cookie-consent.scss';

const CookieConsent = ({handleClose}) => {
	return (
		<div className="CookieConsent">
			<div className="CookieConsent-text">{cookieUiTexts.popUpText}</div>
			<div className="CookieConsent-button">
				<Button class="cookie" text={cookieUiTexts.acceptBtn} onClick={() => {handleClose();}} />
			</div>
		</div>
	);
};

CookieConsent.propTypes = {
	handleClose: PropTypes.func.isRequired
};

export default CookieConsent;