import React from 'react';
import PropTypes from 'prop-types';
import './story-button.scss';

const StoryButton = ({page, onClick}) => {
	return (
		<div className={'StoryButton StoryButton--' + page} onClick={onClick}>
			<div className="StoryButton-icon" />
		</div>
	);
};

StoryButton.defaultProptypes = {
	page: 'activityDescription'
};

StoryButton.propTypes = {
	page: PropTypes.string,
	onClick: PropTypes.func.isRequired
};

export default StoryButton;