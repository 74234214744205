import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {popUpUiTexts} from 'data/ui-texts';
import './activity-popup.scss';

const ActivityPopup = (props) => {
	let {
		isOpen, 
		activity, 
		selectedActivityIds, 
		toggleActivity, 
		handleAnimateMaterialsIcon,
		toggleActivityDescription, 
		toggleActivityStory, 
		closePopup
	} = props;

	if (!isOpen) return null;

	let className = 'ActivityPopup' + (isOpen ? ' ActivityPopup--show' : '');
	let isSelected = selectedActivityIds.indexOf(activity.id) >= 0;

	return (
		<div className={className} onClick={(e) => {e.stopPropagation();}}>
			<div className="ActivityPopup-body">

				{/* Close button */}
				<div className="ActivityPopup-closeBtn" onClick={() => {closePopup();}}></div>

				{/* Title */}
				<div className="ActivityPopup-title">{activity.title}</div>

				{/* Activity description */}
				<div 
					className={'ActivityPopup-activity' + 
						(!activity.storyFile ? ' ActivityPopup-activity--fullWidth' : '')}
				>
					<div className="ActivityPopup-tracks"/>
					<div className="ActivityPopup-text">
						<div className="ActivityPopup-textTitle">{popUpUiTexts.activity}:</div>
						{renderMarkdown(activity.synopsis)}
					</div>
					<div className="ActivityPopup-openActivity" onClick = {() => {toggleActivityDescription(true);}}>
						{popUpUiTexts.openActivity}
					</div>
				</div>

				{/* Story description */}
				{activity.storyFile && <div className="ActivityPopup-story">
					<div className="ActivityPopup-book" />
					<div className="ActivityPopup-text">
						<div className="ActivityPopup-textTitle"> {popUpUiTexts.story}: {activity.storyTitle}</div>
						{activity.storyDescription && renderMarkdown(activity.storyDescription)}
					</div>
					<div className="ActivityPopup-readStory" onClick = {() => {toggleActivityStory(true);}}>
						{popUpUiTexts.readStory}
					</div>
				</div>}

				{/* Toggle activity */}
				<div className="ActivityPopup-toggleActivity">
					<div className="ActivityPopup-label">{popUpUiTexts.addToPlan}</div>
					<div 
						className={'ActivityPopup-checkBox' + (isSelected ? ' ActivityPopup-checkBox--selected' : '')} 
						onClick={(event) => {
							event.stopPropagation(); 
							handleAnimateMaterialsIcon(!isSelected);
							toggleActivity(activity.id);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

ActivityPopup.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	activity: PropTypes.object,
	selectedActivityIds: PropTypes.array.isRequired,
	toggleActivity: PropTypes.func.isRequired,
	handleAnimateMaterialsIcon: PropTypes.func.isRequired,
	toggleActivityDescription: PropTypes.func.isRequired,
	toggleActivityStory: PropTypes.func.isRequired,
	closePopup: PropTypes.func.isRequired
};

export default ActivityPopup;