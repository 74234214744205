import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {setCookie, getCookie} from 'helpers/cookie-helper';
import CookieConsent from './cookie-consent';

class CookieConsentController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showCookieConsent: false
		};
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		let showCookieConsent = true;
		this.loadCookieConsent().then((response) => {
			if (response.status === 'ok' && response.accepted === true) showCookieConsent = false;
			this.setState({showCookieConsent: showCookieConsent}); 
		});
	};

	/**
	 * Load cookie consent from cache / cookie
	 */
	loadCookieConsent = () => {
		return new Promise((resolve) => {
			if ('serviceWorker' in navigator && appConfig.env !== 'development') {
				/* Use service worker */
				fetch(appConfig.cookieConsentEndpoint).then((response) => {return response.json();}).then((data) => {
					if (data && data.hasOwnProperty('accepted')) {
						resolve({status: 'ok', accepted: data.accepted});
					} else {
						resolve({status: 'ok', accepted: false});
					}
				}, (error) => {resolve({status: 'error', error});});
			} else {
				/* Use standard cookie */
				let cookie = getCookie(appConfig.cookieConsentName);
				let accepted = false;
				if (cookie.length > 0 && cookie === 'ok') accepted = true;
				resolve({status: 'ok', accepted: accepted});
			}
		});
	};

	/**
	 * Update cookie consent cache / cookie
	 * @param {bool} isCookieAccepted 
	 */
	updateCookieConsent = (isCookieAccepted) => {
		if ('serviceWorker' in navigator && appConfig.env !== 'development') {
			/* Use service worker */
			fetch(appConfig.cookieConsentEndpoint, {method: 'POST', body: JSON.stringify({accepted: isCookieAccepted})
			}).then(() => {
				this.setState({showCookieConsent: !isCookieAccepted});
			}, (error) => {console.error(error);});
		} else {
			/* Use standard cookie */
			setCookie(appConfig.cookieConsentName, 'ok', 365);
		}
	};

	/**
	 * Close cookie popup
	 */
	handleClose = () => {
		this.updateCookieConsent(true);
		this.setState({showCookieConsent: false});
		this.props.handleShowPWAPopup();
	};

	/**
	 * Render component
	 */
	render = () => {
		if (this.state.showCookieConsent) return <CookieConsent handleClose = {this.handleClose}/>;
		return null;
	};
}

CookieConsentController.propTypes = {
	handleShowPWAPopup: PropTypes.func.isRequired,
};

export default CookieConsentController;