import React from 'react';
import PropTypes from 'prop-types';
import Introduction from 'components/introduction/introduction';
import './introduction-popup.scss';

const IntroductionPopup = ({isOpen, closePopup}) => {
	let className = 'IntroductionPopup' + (isOpen ? ' IntroductionPopup--show' : ''); 
	return (
		<div className={className}>
			<div className="IntroductionPopup-body">
				<div className="IntroductionPopup-closeBtn" onClick={() => {closePopup();}} />
				<Introduction type="popup" />
			</div>
		</div>
	);
};

IntroductionPopup.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closePopup: PropTypes.func.isRequired
};

export default IntroductionPopup;