import React from 'react';
import './dust-particles.scss';

const DustParticles = () => {


	return (
		<div className="DustParticles">	
			<div className="DustParticles-dustParticle DustParticles-dustParticle--1" />
			<div className="DustParticles-dustParticle DustParticles-dustParticle--2" />
		</div>
	);
};



export default DustParticles;