import React from 'react';
import PropTypes from 'prop-types';
import './back-button.scss';

const BackButton = ({color, onClick}) => {
	return <div className={'BackButton BackButton--' + color} onClick={onClick} />;
};

BackButton.defaultProps = {
	color: 'white'
};

BackButton.propTypes = {
	color: PropTypes.string,
	onClick: PropTypes.func.isRequired
};

export default BackButton;