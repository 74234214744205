const pdfsData = [
	// {
	// 	title: 'Bliv klog på',
	// 	subtitle: 'dyrespor',
	// 	id: 'bliv-klog-paa-dyrespor',
		
	// },
	{
		title: 'Dyr',
		subtitle: 'fra jægerstenalderen',
		id: 'dyr'
	},
	{
		title: 'Træer og buske',
		subtitle: 'med bær og frugt',
		id: 'traeer-og-buske'
	},
	// {
	// 	title: 'Dyrefodspor',
	// 	subtitle: 'fra jægerstenalderen',
	// 	id: 'dyrefodspor'
	// },
	{
		title: 'Ask & Liv',
		subtitle: null,
		id: 'farvelaegning-ask-og-liv'
	},
	{
		title: 'Mærker',
		subtitle: null,
		id: 'farvelaegning-maerker'
	},
	// {
	// 	title: 'Min dyrebog',
	// 	subtitle: null,
	// 	id: 'min-dyrebog'
	// },
	// {
	// 	title: 'Naturens',
	// 	subtitle: 'grønne spisekammer',
	// 	id: 'naturens-groenne-spisekammer'
	// },
	// {
	// 	title: 'Opskrifter',
	// 	subtitle: 'på stenaldermad',
	// 	id: 'opskrifter'
	// },
	{
		title: 'Jægerstenalder',
		subtitle: 'puslespil',
		id: 'puslespil'
	},
	// {
	// 	title: 'Tegn',
	// 	subtitle: 'fra jægerstenalderen',
	// 	id: 'tegn'
	// },
	// {
	// 	title: 'Vendespil',
	// 	id: 'vendespil'
	// },
];

export default pdfsData;