import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import BackButton from 'components/layout/back-button';
import './about.scss';

const About = ({selectPage}) => {
	/* Load about text */
	let aboutText = null;
	try {
		aboutText = require('data/pages/about.md');
	} catch (e) {
		console.error('Could not find text: data/pages/about.md');
	}

	return (
		<div className="About">
			<BackButton color="red" onClick={() => {selectPage('chapterOverview');}} />
			<div className="About-body">
				<div className="About-text">{aboutText && renderMarkdown(aboutText.default)}</div>
				<div className="About-logo" />
			</div>
		</div>
	);
};

About.propTypes = {
	selectPage: PropTypes.func.isRequired,
};

export default About;