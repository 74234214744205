/**
 * Register the service worker
 * Configuration file is located at './service-worker-config.js'
 */ 

let installingWorker;
let refreshing;

/* Check if on localhost */
const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === '[::1]' ||
		// 127.0.0.0/8 are considered localhost for IPv4.
		window.location.hostname.match(
			/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
		)
);

/* Clicking the "opdater" button in the SW notification popup will activiate the new SW */
document.getElementById('SWReload').addEventListener('click', () => {
	if (installingWorker) installingWorker.postMessage({action: 'skipWaiting'});
});


/**
 * Check if a SW should be registered or not
 * @param {*} config 
 */
export function register(config) {
	if (
		'serviceWorker' in navigator && 
		(process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'demo' || process.env.NODE_ENV === 'production')
	) {
		// The URL constructor is available in all browsers that support SW.
		const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
		if (publicUrl.origin !== window.location.origin) {
			// Our service worker won't work if PUBLIC_URL is on a different origin
			// from what our page is served on. This might happen if a CDN is used to
			// serve assets; see https://github.com/facebook/create-react-app/issues/2374
			return;
		}

		/* Register SW on window load */
		window.addEventListener('load', () => {
			const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
			if (isLocalhost) {
				/* Localhost, check if a SW still exists or not */
				checkValidServiceWorker(swUrl, config);
				navigator.serviceWorker.ready.then(() => {
					console.log('This web app is being served cache-first by a service worker.');
				});
			/* Server, service worker is registered */
			} else {
				registerValidSW(swUrl);
			}
		});
	}
}

/**
 * Register service worker
 * @param {string} swUrl 
 */
function registerValidSW(swUrl) {
	/* Registration */
	console.log('Registering SW ...');
	navigator.serviceWorker.register(swUrl, {scope: '/'}).then((registration) => {
		/* Registration successful */
		console.log('SW registered.');
		console.log('ServiceWorker registration successful with scope: ', registration.scope);

		/* Already installed */
		if (navigator.serviceWorker.controller) {
			console.log('SW is already installed.');
			let notification = document.getElementById('SWOfflineNotification');
			if (notification) {
				notification.className = 'show';
				setTimeout(() => {notification.classList.remove('show');}, 5000);
			}
		}
		
		/* Not installed / updates available for installation */
		registration.onupdatefound = () => {
			installingWorker = registration.installing;
			if (!installingWorker) return;
			console.log('Installing new / updated SW ...');
			
			installingWorker.onstatechange = () => {
				switch (installingWorker.state) {
				case 'installed':
					if (navigator.serviceWorker.controller) {
						/* SW update is availble on refresh  */
						console.log('New SW installed, is available when all tabs for this page are closed.');
						/* Show notification */
						let notification = document.getElementById('SWNotification');
						notification.className = 'show';	
					} else {
						console.log('Installation done. Content is cached for offline use.');
						let notification = document.getElementById('SWOfflineNotification');
						if (notification) {
							notification.className = 'show';
							setTimeout(() => {notification.classList.remove('show');}, 5000);
						}

					}
					break;
				case 'redundant':
					console.log('The installing SW became redundant.');
					break;
				default: 
					console.log('Installation failed. State: ', (installingWorker.state ? installingWorker.state : ''));
				};
			};
		};
	}).catch((error) => {
		console.error('Error during service worker registration:', error);
	});

	/* Refresh window when switching to new controller */
	navigator.serviceWorker.addEventListener('controllerchange', () => {
		console.log('controller switch');
		if (refreshing) return;
		window.location.reload();
		refreshing = true;
	});
}

/**
 * Check if SW is valid (localhost)
 * @param {string} swUrl 
 * @param {*} config 
 */
function checkValidServiceWorker(swUrl, config) {
	console.log('checkValidServiceWorker');
	/* Check if the service worker can be found. If it can't reload the page. */
	fetch(swUrl).then((response) => {
		/* Ensure service worker exists, and that we really are getting a JS file. */
		const contentType = response.headers.get('content-type');
		if (
			response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
		) {
			/* No service worker found. Probably a different app. Reload the page. */
			navigator.serviceWorker.ready.then((registration) => {
				registration.unregister().then(() => {
					window.location.reload();
				});
			});
		} else {
			/* Service worker found. Proceed as normal. */
			registerValidSW(swUrl, config);
		}
	}).catch(() => {
		console.log('No internet connection found. App is running in offline mode.');
	});
}

/**
 * Unregister SW
 */
export function unregister() {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.ready.then((registration) => {
			registration.unregister();
		});
	}
}