import React from 'react';
import PropTypes from 'prop-types';
import {getImageSize, getXPosition, getYPosition} from 'helpers/animation-helper';
import './taste-stoneage-food.scss';

const TasteStoneageFood = ({containerWidth, containerHeight}) => {
	let waterBlinkPos1 = [82, 44.4];
	let waterBlinkPos2 = [82.8, 43.2];
	let waterBlinkPos3 = [85.1, 43.9];
	let waterDropsTop = 29;
	let waterDropsHeight = 0.149 * containerHeight;


	if (containerWidth > 0 && containerHeight > 0) {
		let imageRatio = 1024. / 768.;

		let [imageWidth, imageHeight] = getImageSize(containerWidth, containerHeight, imageRatio);
		if (imageWidth > containerWidth) {
			waterBlinkPos1[0] = getXPosition(containerWidth, imageWidth, 'center', waterBlinkPos1[0]);
			waterBlinkPos2[0] = getXPosition(containerWidth, imageWidth, 'center', waterBlinkPos2[0]);
			waterBlinkPos3[0] = getXPosition(containerWidth, imageWidth, 'center', waterBlinkPos3[0]);
		}
		if (imageHeight > containerHeight) {
			waterBlinkPos1[1] = getYPosition(containerHeight, imageHeight, 'bottom', waterBlinkPos1[1]);
			waterBlinkPos2[1] = getYPosition(containerHeight, imageHeight, 'bottom', waterBlinkPos2[1]);
			waterBlinkPos3[1] = getYPosition(containerHeight, imageHeight, 'bottom', waterBlinkPos3[1]);
			waterDropsTop = getYPosition(containerHeight, imageHeight, 'bottom', waterDropsTop);
			waterDropsHeight = 0.149 * imageHeight;
		}
	}


	return (
		<div className="TasteStoneageFood">
			{/* Rings in the water */}
			<div className="TasteStoneageFood-rings" />
			<div className="TasteStoneageFood-rings TasteStoneageFood-rings--1" />
			<div className="TasteStoneageFood-rings TasteStoneageFood-rings--2" />
			<div className="TasteStoneageFood-rings TasteStoneageFood-rings--3" />

			{/* Falling drops */}
			<div 
				className="TasteStoneageFood-waterDrops"
				style={{
					height: waterDropsHeight + 'px',
					top: waterDropsTop + '%', 
					right: (100 - waterBlinkPos3[0]) + '%', 
					left: waterBlinkPos1[0] + '%'
				}}
			>
				<div className="TasteStoneageFood-waterDrop TasteStoneageFood-waterDrop--1" />
				<div className="TasteStoneageFood-waterDrop TasteStoneageFood-waterDrop--2" />
				<div className="TasteStoneageFood-waterDrop TasteStoneageFood-waterDrop--3" />
			</div>

			{/* Drops hitting water */}
			<div 
				className="TasteStoneageFood-waterBlink TasteStoneageFood-waterBlink--1" 
				style={{left: waterBlinkPos1[0] + '%', top: waterBlinkPos1[1] + '%'}}		
			/>
			<div 
				className="TasteStoneageFood-waterBlink TasteStoneageFood-waterBlink--2" 
				style={{left: waterBlinkPos2[0] + '%', top: waterBlinkPos2[1] + '%'}}
			/>
			<div 
				className="TasteStoneageFood-waterBlink TasteStoneageFood-waterBlink--3" 
				style={{left: waterBlinkPos3[0] + '%', top: waterBlinkPos3[1] + '%'}}
			/>

			{/* Ask */}
			<div className="TasteStoneageFood-ask TasteStoneageFood-ask--1" />
			<div className="TasteStoneageFood-ask TasteStoneageFood-ask--2" />
			<div className="TasteStoneageFood-ask TasteStoneageFood-ask--3" />
			
			{/* Character eyes */}
			<div className="TasteStoneageFood-askEyes" />
			<div className="TasteStoneageFood-livEye" />

		</div>
	);
};


TasteStoneageFood.propTypes = {
	containerWidth: PropTypes.number.isRequired,
	containerHeight: PropTypes.number.isRequired
};


export default TasteStoneageFood;