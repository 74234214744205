import React from 'react';
import PropTypes from 'prop-types';
import chapterData from 'data/chapter-data';
import {generalUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import BackButton from 'components/layout/back-button';
import './stories.scss';

const Stories = ({isInStandaloneMode, prevPage, handleShowPWAPopup, selectPage, toggleStory}) => {
	/* Load stories text */
	let storiesText = null;
	try {
		storiesText = require('data/pages/stories.md');
	} catch (e) {
		console.error('Could not find text: data/pages/stories.md');
	}

	return (
		<div className="Stories">
			{/* Back button */}
			<BackButton onClick={() => {selectPage(prevPage);}}/>

			{/* Open PWA btn */}
			{!isInStandaloneMode && 
				<div className="Stories-downloadBtn" onClick={() => {handleShowPWAPopup();}} />}

			{/* Header */}
			<div className="Stories-header">{storiesText && renderMarkdown(storiesText.default)}</div>

			{/* Body */}
			<div className="Stories-body">
				{/* Loop over chapters */}
				{chapterData.map((chapter, chapterIndex) => {
					return (
						<div key={chapterIndex} className="Stories-part">
							{/* Chapter header */}
							<div className="Stories-partHeader">
								{generalUiTexts.part + ' ' + (chapterIndex + 1)}
							</div>
							{/* Chapter body */}
							<div className="Stories-partBody">
								{/* Loop over themes */}
								{chapter.themes.map((theme, themeIndex) => {
									return (
									/* Loop over activities */
										theme.activities.map((activity, activityIndex) => {
											/* Activity */
											if (activity.storyFile) {
												return (
													<div 
														key={activityIndex} 
														className="Stories-activity"
														onClick={() => {
															toggleStory(chapterIndex, themeIndex, activityIndex);
														}}
													>
														<div className="Stories-activityText">
															{activity.storyTitle}
														</div>
													</div>											
												);
											}
											return null;
										})
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

Stories.propTypes = {
	isInStandaloneMode: PropTypes.bool.isRequired,
	prevPage: PropTypes.string.isRequired,
	handleShowPWAPopup: PropTypes.func.isRequired,
	selectPage: PropTypes.func.isRequired,
	toggleStory: PropTypes.func.isRequired,
};

export default Stories;