import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {emailUiTexts, generalUiTexts} from 'data/ui-texts';
import apiHelper from '../../helpers/api-helper';
import Popup from './popup';

class PopupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPopup: false,
			isLoading: false,
			isSendingEmail: false,
			popupClass: null,
			popupTitle: null,
			popupText: null,
			popupTextLinks: [],
			popupBtns: [],
			showCloseBtn: true,
			showEmailForm: false,
			emailInputField: '',
			emailFeedback: null,
			emailContent: null,
			printFeedback: null
		};
	};
	
	/**
	 * Open popup
	 * @param {string} popupClass 
	 * @param {string} popupTitle
	 * @param {string} popupText
	 * @param {array}  popupBtns
	 * @param {bool}   showCloseBtn
	 * @param {bool}   showEmailForm
	 */
	openPopup = (
		popupClass = null, 
		popupTitle = null, 
		popupText = null, 
		popupTextLinks = [],
		popupBtns = [], 
		showCloseBtn = true, 
		showEmailForm = false,
		emailContent = null
	) => {
		this.setState({
			isLoading: false,
			showPopup: true, 
			popupClass: popupClass,
			popupTitle: popupTitle,
			popupText: popupText,
			popupTextLinks: popupTextLinks,
			popupBtns: popupBtns,
			showCloseBtn: showCloseBtn,
			showEmailForm: showEmailForm,
			emailContent: emailContent
		}, () => {
			if (popupTextLinks && popupTextLinks.length > 0) {
				popupTextLinks.forEach((link) => {
					let linkElement = document.getElementById(link.id);
					if (linkElement) {
						linkElement.onclick = () => {link.action(...link.actionParams);};
					}
				});
			}
		});
	};

	/**
	 * Close popup
	 */
	closePopup = () => {
		this.setState({
			isLoading: false,
			isSendingEmail: false,
			showPopup: false,
			popupClass: null,
			popupTitle: null,
			popupText: null,
			popupTextLinks: [],
			popupBtns: [],		
			showCloseBtn: true,
			showEmailForm: false,
			emailInputField: '',
			emailFeedback: null,
			emailContent: '',
			printFeedback: ''
		});
	};

	/**
	 * Handle button click
	 * @param {object} button
	 */
	handleButtonClick = (button) => {
		if (this.state.isLoading) return;
		if (button.showLoadingIcon) this.setState({isLoading: true});
		if (button.class === 'printPlan' && button.actionParams[0].length === 0) {
			this.setState({printFeedback: generalUiTexts.noActivitiesSelected2});
			return;
		}
		button.action(...button.actionParams);
	};

	/**
	 * Updates controlled text input field
	 * @param {object} event 
	 */
	handleTextInputChange = (event) => {
		this.setState({[event.target.name]: event.target.value});
	};

	/**
	 * Checks email address and send e-mail if valid
	 */
	sendEmail = () => {
		if (this.state.isSendingEmail) return;
		if (this.state.emailContent.length === 0) {
			this.setState({emailFeedback: generalUiTexts.noActivitiesSelected2});
			return;
		}
		let email = this.state.emailInputField;
		let re = /\S+@\S+\.\S+/;
		if (re.test(email)) {
			this.setState({isSendingEmail: true, emailFeedback: ''});
			let type = (this.state.popupClass === 'printStories' ? 'stories' : 'activities');
			apiHelper('user/send-email', {
				email: email,
				content: this.state.emailContent,
				type: type
			}).then((response) => {
				if (response.status === 'success') {
					this.setState({
						isSendingEmail: false,
						isLoading: false,
						emailFeedback: emailUiTexts.emailSent
					});
				}
			}, (error) => {
				console.error(error);
				this.setState({isLoading: false, isSendingEmail: false, emailFeedback: emailUiTexts.emailError});
			}
			);
		} else {
			this.setState({emailFeedback: emailUiTexts.emailError});
		}
	};

	/**
	 * Render component
	 */
	render = () => {
		let onlyChild = React.Children.only(this.props.children);
		let childrenCloned = React.cloneElement(onlyChild, {
			popupIsOpen: this.state.showPopup,
			openPopup: this.openPopup,
			closePopup: this.closePopup
		});

		return (
			<React.Fragment>
				{this.state.showPopup && 
					<div className="Popup-overlay" onClick={() => {this.closePopup();}}>
						<Popup
							isLoading = {this.state.isLoading}
							isSendingEmail = {this.state.isSendingEmail}
							popupClass = {this.state.popupClass}
							popupTitle = {this.state.popupTitle}		
							popupText = {this.state.popupText}
							popupBtns = {this.state.popupBtns}
							showCloseBtn = {this.state.showCloseBtn}
							showEmailForm = {this.state.showEmailForm}
							emailInputField = {this.state.emailInputField}
							emailFeedback = {this.state.emailFeedback}
							handleButtonClick = {this.handleButtonClick}
							handleTextInputChange = {this.handleTextInputChange}
							sendEmail = {this.sendEmail}
							closePopup = {this.closePopup}
							printFeedback = {this.state.printFeedback}
						/>
					</div>
				}
				{childrenCloned}
			</React.Fragment>
		);
	};
}

PopupController.propTypes = {
	children: PropTypes.any.isRequired
};

export default PopupController;