import React from 'react';
import PropTypes from 'prop-types';
import {getImageSize, getXPosition, getYPosition} from 'helpers/animation-helper';
import './build-huts.scss';

const BuildHuts = ({limitAnimations, containerWidth, containerHeight}) => {
	let hareEarOrigin = [16.92, 17.14];
	let birdOrigin = [44.1, 17.8];

	if (containerWidth > 0 && containerHeight > 0) {
		let imageRatio = 1024. / 626.;

		let [imageWidth, imageHeight] = getImageSize(containerWidth, containerHeight, imageRatio);
		if (imageWidth > containerWidth) {
			hareEarOrigin[0] = getXPosition(containerWidth, imageWidth, 'center', hareEarOrigin[0]);
			birdOrigin[0] = getXPosition(containerWidth, imageWidth, 'center', birdOrigin[0]);
		}
		if (imageHeight > containerHeight) {
			hareEarOrigin[1] = getYPosition(containerHeight, imageHeight, 'top', hareEarOrigin[1]);
			birdOrigin[1] = getYPosition(containerHeight, imageHeight, 'top', birdOrigin[1]);
		}
	}


	return (
		<div className="BuildHuts">
			{/* Hare */}
			<div className="BuildHuts-hare">
				<div 
					className="BuildHuts-hare-ear" 
					style={{
						WebkitTransformOrigin: hareEarOrigin[0] + '% ' + hareEarOrigin[1] + '%',
						transformOrigin: hareEarOrigin[0] + '% ' + hareEarOrigin[1] + '%'
					}} 
				/>
			</div>

			{/* Bush */}
			<div className="BuildHuts-bush" />

			{/* Bird */}
			<div 
				className="BuildHuts-bird" 
				style={{
					WebkitTransformOrigin: birdOrigin[0] + '% ' + birdOrigin[1] + '%',
					transformOrigin: birdOrigin[0] + '% ' + birdOrigin[1] + '%'
				}} 
			/>

			{/* Ask's hand */}
			<div className="BuildHuts-askHand-1" />
			<div className="BuildHuts-askHand-2" />
			<div className="BuildHuts-askHand-3" />
			
			{/* Character eyes */}
			<div className="BuildHuts-askEye" />
			<div className="BuildHuts-livEye" />

			{/* Sun beams */}
			{!limitAnimations && <React.Fragment>
				<div className="BuildHuts-sunbeam BuildHuts-sunbeam--1" />
				<div className="BuildHuts-sunbeam BuildHuts-sunbeam--2" />
				<div className="BuildHuts-sunbeam BuildHuts-sunbeam--3" />
				<div className="BuildHuts-sunbeam BuildHuts-sunbeam--4" />
				<div className="BuildHuts-sunbeam BuildHuts-sunbeam--5" />
				<div className="BuildHuts-sunbeam BuildHuts-sunbeam--6" />
			</React.Fragment>}
			{limitAnimations && 	<div className="BuildHuts-sunbeam"/>}

		</div>
	);
};


BuildHuts.propTypes = {
	limitAnimations: PropTypes.bool.isRequired,
	containerWidth: PropTypes.number.isRequired,
	containerHeight: PropTypes.number.isRequired
};


export default BuildHuts;