import React from 'react';
import PropTypes from 'prop-types';
import {getImageSize, getXPosition, getYPosition} from 'helpers/animation-helper';
import './light-a-fire.scss';

const LightAFire = ({containerWidth, containerHeight}) => {
	let posDrop1 = [49.3, 40.2];
	let posDrop2 = [56.8, 30.2];
	let posDrop3 = [62, 31.3];
	let posDrop4 = [62.6, 31.3];
	let posFire = [46.2, 69.1];
	let fontSizeScale = 1;

	if (containerWidth > 0 && containerHeight > 0) {
		let imageRatio = 1024. / 626.;
		let [imageWidth, imageHeight] = getImageSize(containerWidth, containerHeight, imageRatio);
		fontSizeScale = (imageWidth / containerWidth) * 0.72;
		if (imageWidth > containerWidth) {
			posDrop1[0] = getXPosition(containerWidth, imageWidth, 'center', posDrop1[0]);
			posDrop2[0] = getXPosition(containerWidth, imageWidth, 'center', posDrop2[0]);
			posDrop3[0] = getXPosition(containerWidth, imageWidth, 'center', posDrop3[0]);
			posDrop4[0] = getXPosition(containerWidth, imageWidth, 'center', posDrop4[0]);
			posFire[0] = getXPosition(containerWidth, imageWidth, 'center', posFire[0]);
		}
		if (imageHeight > containerHeight) {
			posDrop1[1] = getYPosition(containerHeight, imageHeight, 'center', posDrop1[1]);
			posDrop2[1] = getYPosition(containerHeight, imageHeight, 'center', posDrop2[1]);
			posDrop3[1] = getYPosition(containerHeight, imageHeight, 'center', posDrop3[1]);
			posDrop4[1] = getYPosition(containerHeight, imageHeight, 'center', posDrop4[1]);
			posFire[1] = getYPosition(containerHeight, imageHeight, 'center', posFire[1]);
		}
	}
		
	return (
		<div className="LightAFire">

			{/* Water drops */}			
			<div 
				className="LightAFire-drop LightAFire-drop--1" 
				style={{left: posDrop1[0] + '%', top: posDrop1[1] + '%'}}
			/>
			<div 
				className="LightAFire-drop LightAFire-drop--2" 
				style={{left: posDrop2[0] + '%', top: posDrop2[1] + '%'}}
			/>
			<div 
				className="LightAFire-drop LightAFire-drop--3" 
				style={{left: posDrop3[0] + '%', top: posDrop3[1] + '%'}}
			/>
			<div 
				className="LightAFire-drop LightAFire-drop--4"
				style={{left: posDrop4[0] + '%', top: posDrop4[1] + '%'}}
			/>

			{/* Ask & Liv */}
			<div className="LightAFire-characters" />
			<div className="LightAFire-characters LightAFire-characters--dark" />
			<div className="LightAFire-characters LightAFire-characters--bright" />

			{/* Fire */}
			<div 
				className="LightAFire-fire" 
				style={{fontSize: fontSizeScale + 'em', left: posFire[0] + '%', top: posFire[1] + '%'}}
			>
				<div className="LightAFire-glow LightAFire-glow--1" />
				<div className="LightAFire-glow LightAFire-glow--2" />
				<div className="LightAFire-glow LightAFire-glow--3" />
				<div className="LightAFire-spark LightAFire-spark--1" />
				<div className="LightAFire-spark LightAFire-spark--2" />
				<div className="LightAFire-spark LightAFire-spark--3" />
				<div className="LightAFire-spark LightAFire-spark--4" />
				<div className="LightAFire-spark LightAFire-spark--5" />
			</div>
			
		</div>
	);
};

LightAFire.propTypes = {
	containerWidth: PropTypes.number.isRequired,
	containerHeight: PropTypes.number.isRequired
};

export default LightAFire;