import React from 'react';
import PropTypes from 'prop-types';
import {getImageSize, getXPosition, getYPosition} from 'helpers/animation-helper';
import Dustparticles from './dust-particles/dust-particles';
import './find-animal-tracks.scss';

const FindAnimalTracks = ({limitAnimations, containerWidth, containerHeight}) => {

	let leftFootOrigin = [59.0, 116.3];
	let rightFootOrigin = [93.1, 91.2];
	let highlightPos1 = [34.2, 40.8];
	let highlightPos2 = [34.6, 48.3];
	let highlightPos3 = [37.8, 51.9];
	let highlightPos4 = [43.6, 36.6];
	let highlightPos5 = [47.5, 73.4];
	let highlightPos6 = [59.9, 64.4];
	let highlightPos7 = [67.6, 46.6];
	let fontSizeScale = 1;
	
	if (containerWidth > 0 && containerHeight > 0) {
		let imageRatio = 1024. / 768.;

		let [imageWidth, imageHeight] = getImageSize(containerWidth, containerHeight, imageRatio);
		fontSizeScale = (imageWidth / containerWidth);
		if (imageWidth > containerWidth) {
			highlightPos1[0] = getXPosition(containerWidth, imageWidth, 'center', highlightPos1[0]);
			highlightPos2[0] = getXPosition(containerWidth, imageWidth, 'center', highlightPos2[0]);
			highlightPos3[0] = getXPosition(containerWidth, imageWidth, 'center', highlightPos3[0]);
			highlightPos4[0] = getXPosition(containerWidth, imageWidth, 'center', highlightPos4[0]);
			highlightPos5[0] = getXPosition(containerWidth, imageWidth, 'center', highlightPos5[0]);
			highlightPos6[0] = getXPosition(containerWidth, imageWidth, 'center', highlightPos6[0]);
			highlightPos7[0] = getXPosition(containerWidth, imageWidth, 'center', highlightPos7[0]);
			leftFootOrigin[0] = getXPosition(containerWidth, imageWidth, 'right', leftFootOrigin[0]);
			rightFootOrigin[0] = getXPosition(containerWidth, imageWidth, 'right', rightFootOrigin[0]);
		}
		if (imageHeight > containerHeight) {
			highlightPos1[1] = getYPosition(containerHeight, imageHeight, 'center', highlightPos1[1]);
			highlightPos2[1] = getYPosition(containerHeight, imageHeight, 'center', highlightPos2[1]);
			highlightPos3[1] = getYPosition(containerHeight, imageHeight, 'center', highlightPos3[1]);
			highlightPos4[1] = getYPosition(containerHeight, imageHeight, 'center', highlightPos4[1]);
			highlightPos5[1] = getYPosition(containerHeight, imageHeight, 'center', highlightPos5[1]);
			highlightPos6[1] = getYPosition(containerHeight, imageHeight, 'center', highlightPos6[1]);
			highlightPos7[1] = getYPosition(containerHeight, imageHeight, 'center', highlightPos7[1]);
			leftFootOrigin[1] = getYPosition(containerHeight, imageHeight, 'bottom', leftFootOrigin[1]);
			rightFootOrigin[1] = getYPosition(containerHeight, imageHeight, 'bottom', rightFootOrigin[1]);
		}
	}

	return (
		<div className="FindAnimalTracks">	
			<div className="FindAnimalTracks-shadow" />

			{/* Highlights */}
			<div 
				className="FindAnimalTracks-trackHighlight FindAnimalTracks-trackHighlight--1" 
				style={{fontSize: fontSizeScale + 'em', left: highlightPos1[0] + '%', top: highlightPos1[1] + '%'}}
			/>
			<div 
				className="FindAnimalTracks-trackHighlight FindAnimalTracks-trackHighlight--2" 
				style={{fontSize: fontSizeScale + 'em', left: highlightPos2[0] + '%', top: highlightPos2[1] + '%'}}
			/>
			<div 
				className="FindAnimalTracks-trackHighlight FindAnimalTracks-trackHighlight--3" 
				style={{fontSize: fontSizeScale + 'em', left: highlightPos3[0] + '%', top: highlightPos3[1] + '%'}}
			/>
			<div 
				className="FindAnimalTracks-trackHighlight FindAnimalTracks-trackHighlight--4" 
				style={{fontSize: fontSizeScale + 'em', left: highlightPos4[0] + '%', top: highlightPos4[1] + '%'}}
			/>

			<div 
				className="FindAnimalTracks-trackHighlight FindAnimalTracks-trackHighlight--5" 
				style={{fontSize: fontSizeScale + 'em', left: highlightPos5[0] + '%', top: highlightPos5[1] + '%'}}
			/>
			<div 
				className="FindAnimalTracks-trackHighlight FindAnimalTracks-trackHighlight--6" 
				style={{fontSize: fontSizeScale + 'em', left: highlightPos6[0] + '%', top: highlightPos6[1] + '%'}}
			/>
			<div 
				className="FindAnimalTracks-trackHighlight FindAnimalTracks-trackHighlight--7" 
				style={{fontSize: fontSizeScale + 'em', left: highlightPos7[0] + '%', top: highlightPos7[1] + '%'}}
			/>

			{/* Ask's feet */}
			<div 
				className="FindAnimalTracks-leftFoot" 
				style={{
					WebkitTransformOrigin: leftFootOrigin[0] + '% ' + leftFootOrigin[1] + '%',
					transformOrigin: leftFootOrigin[0] + '% ' + leftFootOrigin[1] + '%'
				}}
			/>
			<div 
				className="FindAnimalTracks-rightFoot" 
				style={{
					WebkitTransformOrigin: rightFootOrigin[0] + '% ' + rightFootOrigin[1] + '%',
					transformOrigin: rightFootOrigin[0] + '% ' + rightFootOrigin[1] + '%'
				}}
			/>

			{/* Dust */}
			{!limitAnimations && <Dustparticles />}
		</div>
	);
};

FindAnimalTracks.propTypes = {
	limitAnimations: PropTypes.bool.isRequired,
	containerWidth: PropTypes.number.isRequired,
	containerHeight: PropTypes.number.isRequired
};

export default FindAnimalTracks;