import React from 'react';
import PropTypes from 'prop-types';
import badgesData from 'data/badges-data';
import {generalUiTexts} from 'data/ui-texts';
import popupBadgesInfoText from 'data/pages/info-badges.md';
import BackButton from 'components/layout/back-button';
import Badge from './badge';
import './badges.scss';

const Badges = (props) => {
	let {
		prevPage, 
		initialSelectedBadgeIds,
		selectedBadgeIds, 
		selectPage, 
		toggleBadges, 
		toggleBadgesSelector, 
		touchEnd,
		openPopup
	} = props;

	return (
		<div className={
			'Badges' + (prevPage === 'configure' ? ' Badges--configure' : '')}
		>

			{/* Rewards */}
			{(initialSelectedBadgeIds.indexOf('animals') < 0 && selectedBadgeIds.indexOf('animals') >= 0) && 
				<div className="Badges-animalsGlow" />}
			{selectedBadgeIds.indexOf('animals') >= 0 && <div className="Badges-animals" />}

			<div className="Badges-foreground" />
			
			{(initialSelectedBadgeIds.indexOf('cave') < 0 && selectedBadgeIds.indexOf('cave') >= 0) && 
				<div className="Badges-caveGlow" />}
			{selectedBadgeIds.indexOf('cave') >= 0 && <div className="Badges-cave" />}

			{(initialSelectedBadgeIds.indexOf('weapons') < 0 && selectedBadgeIds.indexOf('weapons') >= 0 &&
				selectedBadgeIds.indexOf('prey') < 0) && <div className="Badges-weaponsGlow" />}
			{(initialSelectedBadgeIds.indexOf('weapons') < 0 && selectedBadgeIds.indexOf('weapons') >= 0 &&
				selectedBadgeIds.indexOf('prey') >= 0) && <div className="Badges-weaponsGlow2" />}
			{selectedBadgeIds.indexOf('weapons') >= 0 && <div className="Badges-weapons" />}
			
			<div className={'Badges-liv' + 
				(selectedBadgeIds.indexOf('fire') >= 0 ? ' Badges-liv--fire' : '')} 
			/>
			
			<div className={'Badges-ask' + (selectedBadgeIds.indexOf('prey') >= 0 
				? ' Badges-ask--prey' 
				: (selectedBadgeIds.indexOf('weapons') >= 0 ? ' Badges-ask--weapons' : ''))}
			/>
			{(initialSelectedBadgeIds.indexOf('prey') < 0 && selectedBadgeIds.indexOf('prey') >= 0) &&
			<div className="Badges-preyGlow" />}


			
			{(initialSelectedBadgeIds.indexOf('fire') < 0 && selectedBadgeIds.indexOf('fire') >= 0) && 
				<div className="Badges-fireGlow" />}
			{selectedBadgeIds.indexOf('fire') >= 0 && <div className="Badges-fire" />}

			{(initialSelectedBadgeIds.indexOf('magic') < 0 && selectedBadgeIds.indexOf('magic') >= 0) && 
				<div className="Badges-magicGlow" />}
			{selectedBadgeIds.indexOf('magic') >= 0 && <div className="Badges-magic" />}
			
			{(initialSelectedBadgeIds.indexOf('tracks') < 0 && selectedBadgeIds.indexOf('tracks') >= 0) && 
				<div className="Badges-tracksGlow" />}
			{selectedBadgeIds.indexOf('tracks') >= 0 && <div className="Badges-tracks" />}
			

			{(initialSelectedBadgeIds.indexOf('hunting') < 0 && selectedBadgeIds.indexOf('hunting') >= 0) && 
				<div className="Badges-huntingGlow" />}
			{selectedBadgeIds.indexOf('hunting') >= 0 && <div className="Badges-hunting" />}

			{(initialSelectedBadgeIds.indexOf('gatherer') < 0 && selectedBadgeIds.indexOf('gatherer') >= 0) && 
				<div className="Badges-gathererGlow" />}
			{selectedBadgeIds.indexOf('gatherer') >= 0 && <div className="Badges-gatherer" />}


			

			{/* Badges */}
			<div className="Badges-badgesLeft">
				{badgesData.filter((_, index) => {return index < 3;}).map((badge) => {
					let isSelected = (selectedBadgeIds.indexOf(badge.id) >= 0);
					let animate = (isSelected && initialSelectedBadgeIds.indexOf(badge.id) < 0);
					return (
						<Badge
							key = {badge.id}
							isSelected = {isSelected}
							animate = {animate}
							badgeId = {badge.id}
							toggleBadgesSelector = {toggleBadgesSelector}
							touchEnd = {touchEnd}
						/>
					);
				})}
			</div>
			<div className="Badges-badgesCenter">
				{badgesData.filter((_, index) => {return (index > 2 && index < 6);}).map((badge) => {
					let isSelected = (selectedBadgeIds.indexOf(badge.id) >= 0);
					let animate = (isSelected && initialSelectedBadgeIds.indexOf(badge.id) < 0);
					return (
						<Badge
							key = {badge.id}
							isSelected = {isSelected}
							animate = {animate}
							badgeId = {badge.id}
							toggleBadgesSelector = {toggleBadgesSelector}
							touchEnd = {touchEnd}
						/>
					);
				})}
			</div>
			<div className="Badges-badgesRight">
				{badgesData.filter((_, index) => {return (index > 5);}).map((badge) => {
					let isSelected = (selectedBadgeIds.indexOf(badge.id) >= 0);
					let animate = (isSelected && initialSelectedBadgeIds.indexOf(badge.id) < 0);
					return (
						<Badge
							key = {badge.id}
							isSelected = {isSelected}
							animate = {animate}
							badgeId = {badge.id}
							toggleBadgesSelector = {toggleBadgesSelector}
							touchEnd = {touchEnd}
						/>
					);
				})}
			</div>


			{/* Back button */}
			<BackButton 
				color = "white"
				onClick={() => {
					(prevPage === 'configure' && toggleBadges ? toggleBadges(false) : selectPage(prevPage));
				}}
			/>

			{/* Info popup button */}
			<div 
				className="Badges-infoIcon"
				onClick = {() => {openPopup('infoPopup', null, popupBadgesInfoText, [], null, true);}}
			/>

			{/* Title */}
			<div className="Badges-title">{generalUiTexts.badges}</div>
		</div>
	
	);
};

Badges.propTypes = {
	prevPage: PropTypes.string.isRequired,
	initialSelectedBadgeIds: PropTypes.array.isRequired,
	selectedBadgeIds: PropTypes.array.isRequired,
	selectPage: PropTypes.func.isRequired,
	toggleBadgesSelector: PropTypes.func.isRequired,
	toggleBadges: PropTypes.func,
	touchEnd: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired
};

export default Badges;