import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './button.scss';

class Button extends Component {
	/**
	 * Handle click
	 * @param {object} event
	 */
	handleClick = (event) => {
		if (this.props.disabled) event.preventDefault();
		else if (this.props.onClick) {
			event.persist();
			this.props.onClick(event);
		}
	};
	
	/**
	 * Render component
	 */
	render = () => {
		let className = 'Button';
		if (this.props.class) {className += ' Button--' + this.props.class;}	
		if (this.props.disabled) {className += ' Button--disabled';}	
		if (this.props.loading) {className += ' Button--loading';}
		return <div className={className} onClick={this.handleClick}><span>{this.props.text}</span></div>;
	};
}

Button.defaultProps = {
	text: ''
};

Button.propTypes = {
	disabled: PropTypes.bool,	
	loading: PropTypes.bool,
	text: PropTypes.string,
	class: PropTypes.string,
	onClick: PropTypes.func.isRequired
};


export default Button;