const chapterData = [
	/* Del 1 */
	{
		id: 1,
		title: 'Del 1 - Hulen og sporene',
		themes: [
			{
			id: 1,
			isIndoor: true,
			activities: [
				{
					id: 'make-stoneage-clothing',
					title: 'Lav stenalderdragter',
					description: 'Lav stenaldertøj til børn og voksne som optakt til forløbet.',
					synopsis: require('./activities/make-stoneage-clothing/synopsis.md').default,
					descriptionHeader: require('./activities/make-stoneage-clothing/description-header.md').default,
					descriptionTalkAbout: require('./activities/make-stoneage-clothing/description-talk-about.md').default,
					descriptionFacts: require('./activities/make-stoneage-clothing/description-facts.md').default,
					descriptionFocus: require('./activities/make-stoneage-clothing/description-focus.md').default,
					descriptionReasoning: require('./activities/make-stoneage-clothing/description-reasoning.md').default,
					materials: require('./activities/make-stoneage-clothing/materials.md').default,
					illustration: 'lav-stenalderdragter.png',
					storyFile: null,
					storyNumber: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null
				},
				{
					id: 'learn-about-animal-tracks',
					title: 'Bliv klog på dyrespor',
					description: 'Tal om forskellige spor, og kig på billeder af spor.',
					synopsis: require('./activities/learn-about-animal-tracks/synopsis.md').default ,
					descriptionHeader: require('./activities/learn-about-animal-tracks/description-header.md').default,
					descriptionTalkAbout: require('./activities/learn-about-animal-tracks/description-talk-about.md').default,
					descriptionFacts: require('./activities/learn-about-animal-tracks/description-facts.md').default,
					descriptionFocus: require('./activities/learn-about-animal-tracks/description-focus.md').default,
					descriptionReasoning: require('./activities/learn-about-animal-tracks/description-reasoning.md').default,
					materials: require('./activities/learn-about-animal-tracks/materials.md').default,
					illustration: 'bliv-klog-paa-dyrespor.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null
				},
			]
		},
		{
			id: 2,
			isIndoor: false,
			activities: [
				{
					id: 'build-huts',
					title: 'Byg huler',
					description: 'Saml materialer, og byg huler til en boplads.',
					synopsis: require('./activities/build-huts/synopsis.md').default,
					descriptionHeader: require('./activities/build-huts/description-header.md').default,
					descriptionTalkAbout: require('./activities/build-huts/description-talk-about.md').default,
					descriptionFacts: require('./activities/build-huts/description-facts.md').default,
					descriptionFocus: require('./activities/build-huts/description-focus.md').default,
					descriptionReasoning: require('./activities/build-huts/description-reasoning.md').default,
					materials: require('./activities/build-huts/materials.md').default,
					illustration: 'byg-huler.png',
					storyFile: require('./activities/build-huts/story.md').default,
					storyNumber: 1,
					storyTitle: 'Hulen',
					storyDescription: 'Ask og Liv bygger en hule.',
					storyAudio: 'hulen.mp3',
					badgeId: 'cave'
				},
				{
					id: 'light-a-fire',
					title: 'Saml pinde og tænd bål',
					description: 'Saml små tørre kviste, mos og blade. Tænd bål, og tal om flint.',
					synopsis: require('./activities/light-a-fire/synopsis.md').default,
					descriptionHeader: require('./activities/light-a-fire/description-header.md').default,
					descriptionTalkAbout: require('./activities/light-a-fire/description-talk-about.md').default,
					descriptionFacts: require('./activities/light-a-fire/description-facts.md').default,
					descriptionFocus: require('./activities/light-a-fire/description-focus.md').default,
					descriptionReasoning: require('./activities/light-a-fire/description-reasoning.md').default,
					materials: require('./activities/light-a-fire/materials.md').default,
					illustration: 'taand-baal.png',
					storyFile: require('./activities/light-a-fire/story.md').default,
					storyNumber: 2,
					storyTitle: 'Bålet',
					storyDescription: 'At tænde et bål og sætte en fælde.', 
					storyAudio: 'baalet.mp3',
					badgeId: 'fire'
				},
				{
					id: 'find-animal-tracks',
					title: 'Find dyrespor',
					description: 'Led efter alle typer af dyrespor ved bopladsen.',
					synopsis: require('./activities/find-animal-tracks/synopsis.md').default,
					descriptionHeader: require('./activities/find-animal-tracks/description-header.md').default,
					descriptionTalkAbout: require('./activities/find-animal-tracks/description-talk-about.md').default,
					descriptionFacts: require('./activities/find-animal-tracks/description-facts.md').default,
					descriptionFocus: require('./activities/find-animal-tracks/description-focus.md').default,
					descriptionReasoning: require('./activities/find-animal-tracks/description-reasoning.md').default,
					materials: require('./activities/find-animal-tracks/materials.md').default,
					illustration: 'find-dyrespor.png',
					storyFile: require('./activities/find-animal-tracks/story.md').default,
					storyNumber: 3,
					storyTitle: 'Sporene',
					storyDescription: 'Farlige spor og en tom fælde.',
					storyAudio: 'sporene.mp3',
					badgeId: 'tracks'
				},
			]
		},
		{
			id: 3,
			isIndoor: true,
			activities: [
				{
					id: 'draw-animal-tracks',
					title: 'Tegn dyrespor',
					description: 'Mal spor på træskiver, og tal om spor fra bopladsen.',
					synopsis: require('./activities/draw-animal-tracks/synopsis.md').default,
					descriptionHeader: require('./activities/draw-animal-tracks/description-header.md').default,
					descriptionTalkAbout: require('./activities/draw-animal-tracks/description-talk-about.md').default,
					descriptionFacts: require('./activities/draw-animal-tracks/description-facts.md').default,
					descriptionFocus: require('./activities/draw-animal-tracks/description-focus.md').default,
					descriptionReasoning: require('./activities/draw-animal-tracks/description-reasoning.md').default,
					materials: require('./activities/draw-animal-tracks/materials.md').default,
					illustration: 'tegn-dyrespor.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null
				},
				{
					id: 'leaves-and-trees',
					title: 'Blade og træer',
					description: 'Saml blade, og lav en vendespil af pap eller laminering.',
					synopsis: require('./activities/leaves-and-trees/synopsis.md').default,
					descriptionHeader: require('./activities/leaves-and-trees/description-header.md').default,
					descriptionTalkAbout: require('./activities/leaves-and-trees/description-talk-about.md').default,
					descriptionFacts: require('./activities/leaves-and-trees/description-facts.md').default,
					descriptionFocus: require('./activities/leaves-and-trees/description-focus.md').default,
					descriptionReasoning: require('./activities/leaves-and-trees/description-reasoning.md').default,
					materials: require('./activities/leaves-and-trees/materials.md').default,
					illustration: 'blade-og-traeer.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null
				},
				{
					id: 'make-a-clay-bowl',
					title: 'Form en lerskål',
					description: 'Form små skåle af ler, og lad dem tørre.',
					synopsis: require('./activities/make-a-clay-bowl/synopsis.md').default,
					descriptionHeader: require('./activities/make-a-clay-bowl/description-header.md').default,
					descriptionTalkAbout: require('./activities/make-a-clay-bowl/description-talk-about.md').default,
					descriptionFacts: require('./activities/make-a-clay-bowl/description-facts.md').default,
					descriptionFocus: require('./activities/make-a-clay-bowl/description-focus.md').default,
					descriptionReasoning: require('./activities/make-a-clay-bowl/description-reasoning.md').default,
					materials: require('./activities/make-a-clay-bowl/materials.md').default,
					illustration: 'form-en-lerskaal.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null
				},
			]
		},
	],

	/* Del 2 */
	},
	{
		id: 2,
		title: 'Del 2 - Våben og jægertræning',
		themes: [
			{
			id: 1,
			isIndoor: true,
			badge: '',
			activities: [
				{
					id: 'make-a-stoneage-purse',
					title: 'Lav en stenalderpung',
					description: 'Lav stenalderpunge af læder eller stof.',
					synopsis: require('./activities/make-a-stoneage-purse/synopsis.md').default,
					descriptionHeader: require('./activities/make-a-stoneage-purse/description-header.md').default,
					descriptionTalkAbout: require('./activities/make-a-stoneage-purse/description-talk-about.md').default,
					descriptionFacts: require('./activities/make-a-stoneage-purse/description-facts.md').default,
					descriptionFocus: require('./activities/make-a-stoneage-purse/description-focus.md').default,
					descriptionReasoning: require('./activities/make-a-stoneage-purse/description-reasoning.md').default,
					materials: require('./activities/make-a-stoneage-purse/materials.md').default,
					illustration: 'lav-en-pung.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null
				},
				{
					id: 'edible-plants',
					title: 'Spiselige planter',
					description: 'Hvad må man spise, og hvad må man ikke spise?',
					synopsis: require('./activities/edible-plants/synopsis.md').default,
					descriptionHeader: require('./activities/edible-plants/description-header.md').default,
					descriptionTalkAbout: require('./activities/edible-plants/description-talk-about.md').default,
					descriptionFacts: require('./activities/edible-plants/description-facts.md').default,
					descriptionFocus: require('./activities/edible-plants/description-focus.md').default,
					descriptionReasoning: require('./activities/edible-plants/description-reasoning.md').default,
					materials: require('./activities/edible-plants/materials.md').default,
					illustration: 'spiselige-planter.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null
				},
				{
					id: 'make-magic-amulets',
					title: 'Lav magiske amuletter',
					description: 'Lav et halssmykke med træperler af hyldegrene.',
					synopsis: require('./activities/make-magic-amulets/synopsis.md').default,
					descriptionHeader: require('./activities/make-magic-amulets/description-header.md').default,
					descriptionTalkAbout: require('./activities/make-magic-amulets/description-talk-about.md').default,
					descriptionFacts: require('./activities/make-magic-amulets/description-facts.md').default,
					descriptionFocus: require('./activities/make-magic-amulets/description-focus.md').default,
					descriptionReasoning: require('./activities/make-magic-amulets/description-reasoning.md').default,
					materials: require('./activities/make-magic-amulets/materials.md').default,
					illustration: '',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null
				}
			]
		},
		{
			id: 2,
			isIndoor: false,
			activities: [
				{
					id: 'make-a-weapon',
					title: 'Lav et våben',
					description: 'Snit og byg våben, og skyd til måls.',
					synopsis: require('./activities/make-a-weapon/synopsis.md').default,
					descriptionHeader: require('./activities/make-a-weapon/description-header.md').default,
					descriptionTalkAbout: require('./activities/make-a-weapon/description-talk-about.md').default,
					descriptionFacts: require('./activities/make-a-weapon/description-facts.md').default,
					descriptionFocus: require('./activities/make-a-weapon/description-focus.md').default,
					descriptionReasoning: require('./activities/make-a-weapon/description-reasoning.md').default,
					materials: require('./activities/make-a-weapon/materials.md').default,
					illustration: 'lav-et-vaaben.png',
					storyFile: require('./activities/make-a-weapon/story.md').default,
					storyNumber: 1,
					storyTitle: 'På jagt',
					storyDescription: 'Ask og Liv øver sig i at liste.',
					storyAudio: 'paa-jagt.mp3',
					badgeId: 'weapons'
				},
				{
					id: 'move-like-a-hunter',
					title: 'Bevæg jer som jægere',
					description: 'Øv jer i at snige, balancere, gemme og sprinte.',
					synopsis: require('./activities/move-like-a-hunter/synopsis.md').default,
					descriptionHeader: require('./activities/move-like-a-hunter/description-header.md').default,
					descriptionTalkAbout: require('./activities/move-like-a-hunter/description-talk-about.md').default,
					descriptionFacts: require('./activities/move-like-a-hunter/description-facts.md').default,
					descriptionFocus: require('./activities/move-like-a-hunter/description-focus.md').default,
					descriptionReasoning: require('./activities/move-like-a-hunter/description-reasoning.md').default,
					materials: require('./activities/move-like-a-hunter/materials.md').default,
					illustration: 'bevaeg-jer-som-jaegere.png',
					storyFile: require('./activities/move-like-a-hunter/story.md').default,
					storyNumber: 2,
					storyTitle: 'Hjorten',
					storyDescription: 'At følge et spor, og se en hjort.',
					storyAudio: 'hjorten.mp3',
					badgeId: 'hunting'
				},
				{
					id: 'gather-magic',
					title: 'Saml magi',
					description: 'Saml urter til jeres stenalderpunge.',
					synopsis: require('./activities/gather-magic/synopsis.md').default,
					descriptionHeader: require('./activities/gather-magic/description-header.md').default,
					descriptionTalkAbout: require('./activities/gather-magic/description-talk-about.md').default,
					descriptionFacts: require('./activities/gather-magic/description-facts.md').default,
					descriptionFocus: require('./activities/gather-magic/description-focus.md').default,
					descriptionReasoning: require('./activities/gather-magic/description-reasoning.md').default,
					materials: require('./activities/gather-magic/materials.md').default,
					illustration: 'saml-magi.png',
					storyFile: require('./activities/gather-magic/story.md').default,
					storyNumber: 3,
					storyTitle: 'Sporet på stenen',
					storyDescription: 'Bjørnen der forsvandt i en sten.',
					storyAudio: 'sporet-paa-stenen.mp3',
					badgeId: 'gatherer'
				},
			]
		},
		{
			id: 3,
			isIndoor: true,
			activities: [
				{
					id: 'hunting-training',
					title: 'Jagttræning',
					description: 'Træn hurtigløb som jæger og bytte.',
					synopsis: require('./activities/hunting-training/synopsis.md').default,
					descriptionHeader: require('./activities/hunting-training/description-header.md').default,
					descriptionTalkAbout: require('./activities/hunting-training/description-talk-about.md').default,
					descriptionFacts: require('./activities/hunting-training/description-facts.md').default,
					descriptionFocus: require('./activities/hunting-training/description-focus.md').default,
					descriptionReasoning: require('./activities/hunting-training/description-reasoning.md').default,
					materials: require('./activities/hunting-training/materials.md').default,
					illustration: 'jaegertraening.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null
				},
				{
					id: 'memory-game-with-animals',
					title: 'Vendespil med dyr',
					description: 'Lær dyrene fra jægerstenalderen at kende.',
					synopsis: require('./activities/memory-game-with-animals/synopsis.md').default,
					descriptionHeader: require('./activities/memory-game-with-animals/description-header.md').default,
					descriptionTalkAbout: require('./activities/memory-game-with-animals/description-talk-about.md').default,
					descriptionFacts: require('./activities/memory-game-with-animals/description-facts.md').default,
					descriptionFocus: require('./activities/memory-game-with-animals/description-focus.md').default,
					descriptionReasoning: require('./activities/memory-game-with-animals/description-reasoning.md').default,
					materials: require('./activities/memory-game-with-animals/materials.md').default,
					illustration: 'vendespil-med-dyr.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null
				}
			]
		},
	],


	/* Del 3 */
	},
	{
		id: 3,
		title: 'Del 3 - Ånder & jagt',
		themes: [
			{
			id: 1,
			isIndoor: true,
			badge: '',
			activities: [

				{
					id: 'make-a-shaman-staff',
					title: 'Lav en shamanstav',
					description: 'Snit shamanstave af grene.',
					synopsis: require('./activities/make-a-shaman-staff/synopsis.md').default,
					descriptionHeader: require('./activities/make-a-shaman-staff/description-header.md').default,
					descriptionTalkAbout: require('./activities/make-a-shaman-staff/description-talk-about.md').default,
					descriptionFacts: require('./activities/make-a-shaman-staff/description-facts.md').default,
					descriptionFocus: require('./activities/make-a-shaman-staff/description-focus.md').default,
					descriptionReasoning: require('./activities/make-a-shaman-staff/description-reasoning.md').default,
					materials: require('./activities/make-a-shaman-staff/materials.md').default,
					illustration: '',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null
				},
				{
					id: 'make-drawing-charcoals',
					title: 'Lav tegnekul',
					description: 'Lav tegnekul over bål, og se på jægerstenaldertegn.',
					synopsis: require('./activities/make-drawing-charcoals/synopsis.md').default,
					descriptionHeader: require('./activities/make-drawing-charcoals/description-header.md').default,
					descriptionTalkAbout: require('./activities/make-drawing-charcoals/description-talk-about.md').default,
					descriptionFacts: require('./activities/make-drawing-charcoals/description-facts.md').default,
					descriptionFocus: require('./activities/make-drawing-charcoals/description-focus.md').default,
					descriptionReasoning: require('./activities/make-drawing-charcoals/description-reasoning.md').default,
					materials: require('./activities/make-drawing-charcoals/materials.md').default,
					illustration: 'lav-tegnekul.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null
				},
				{
					id: 'carve-a-thunderstick',
					title: 'Snit en brummer (voksen)',
					description: 'Et virkningsfuldt "instrument" at demonstrere.',
					synopsis: require('./activities/carve-a-thunderstick/synopsis.md').default,
					descriptionHeader: require('./activities/carve-a-thunderstick/description-header.md').default,
					descriptionTalkAbout: require('./activities/carve-a-thunderstick/description-talk-about.md').default,
					descriptionFacts: require('./activities/carve-a-thunderstick/description-facts.md').default,
					descriptionFocus: require('./activities/carve-a-thunderstick/description-focus.md').default,
					descriptionReasoning: require('./activities/carve-a-thunderstick/description-reasoning.md').default,
					materials: require('./activities/carve-a-thunderstick/materials.md').default,
					illustration: 'snit-en-brummer.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null
				}
			]
		},
		{
			id: 2,
			isIndoor: false,
			activities: [
				{
					id: 'music-and-spirits',
					title: 'Trom og dans',
					description: 'Hold de onde ånder væk med trommer og dans.',
					synopsis: require('./activities/music-and-spirits/synopsis.md').default,
					descriptionHeader: require('./activities/music-and-spirits/description-header.md').default,
					descriptionTalkAbout: require('./activities/music-and-spirits/description-talk-about.md').default,
					descriptionFacts: require('./activities/music-and-spirits/description-facts.md').default,
					descriptionFocus: require('./activities/music-and-spirits/description-focus.md').default,
					descriptionReasoning: require('./activities/music-and-spirits/description-reasoning.md').default,
					materials: require('./activities/music-and-spirits/materials.md').default,
					illustration: 'musik-og-aandemaneri.png',
					storyFile: require('./activities/music-and-spirits/story.md').default,
					storyNumber: 1,
					storyTitle: 'Shamanen',
					storyDescription: 'Ask og Liv opsøger shamanen.',
					storyAudio: 'shamanen.mp3',
					badgeId: 'animals'
				},
				{
					id: 'draw-magic-symbols',
					title: 'Tegn magiske tegn',					
					description: 'Mal tegn på sten eller træer ved bopladsen.',
					synopsis: require('./activities/draw-magic-symbols/synopsis.md').default,
					descriptionHeader: require('./activities/draw-magic-symbols/description-header.md').default,
					descriptionTalkAbout: require('./activities/draw-magic-symbols/description-talk-about.md').default,
					descriptionFacts: require('./activities/draw-magic-symbols/description-facts.md').default,
					descriptionFocus: require('./activities/draw-magic-symbols/description-focus.md').default,
					descriptionReasoning: require('./activities/draw-magic-symbols/description-reasoning.md').default,
					materials: require('./activities/draw-magic-symbols/materials.md').default,
					illustration: 'tegn-magiske-tegn.png',
					storyFile: require('./activities/draw-magic-symbols/story.md').default,
					storyNumber: 2,
					storyTitle: 'Ånden',
					storyDescription: 'At tale med ånder og vende jagtlykken.',
					storyAudio: 'aanden.mp3',
					badgeId: 'magic'
				},
				{
					id: 'taste-stoneage-food',
					title: 'Smag på stenaldermad',
					description: 'Lav smagsprøver på stenaldermad.',
					synopsis: require('./activities/taste-stoneage-food/synopsis.md').default,
					descriptionHeader: require('./activities/taste-stoneage-food/description-header.md').default,
					descriptionTalkAbout: require('./activities/taste-stoneage-food/description-talk-about.md').default,
					descriptionFacts: require('./activities/taste-stoneage-food/description-facts.md').default,
					descriptionFocus: require('./activities/taste-stoneage-food/description-focus.md').default,
					descriptionReasoning: require('./activities/taste-stoneage-food/description-reasoning.md').default,
					materials: require('./activities/taste-stoneage-food/materials.md').default,
					illustration: 'smag-paa-stenalderkost.png',
					storyFile: require('./activities/taste-stoneage-food/story.md').default,
					storyNumber: 3,
					storyTitle: 'Byttet',
					storyDescription: 'Ask og Liv bliver rigtige jægere.',
					storyAudio: 'byttet.mp3',
					badgeId: 'prey'
				},
			]
		},
		{
			id: 3,
			isIndoor: true,
			activities: [
				{
					id: 'make-stoneage-art',
					title: '"Hulemalerier" og historier',
					description: 'Lav kunstværker med kul på træskiver, papir eller pap.',
					synopsis: require('./activities/make-stoneage-art/synopsis.md').default,
					descriptionHeader: require('./activities/make-stoneage-art/description-header.md').default,
					descriptionTalkAbout: require('./activities/make-stoneage-art/description-talk-about.md').default,
					descriptionFacts: require('./activities/make-stoneage-art/description-facts.md').default,
					descriptionFocus: require('./activities/make-stoneage-art/description-focus.md').default,
					descriptionReasoning: require('./activities/make-stoneage-art/description-reasoning.md').default,
					materials: require('./activities/make-stoneage-art/materials.md').default,
					illustration: 'lav-stenalderkunst.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null
				},
				{
					id: 'visit-by-a-hunter',
					title: 'Få besøg af en jæger',
					description: 'Lav jægerstenaldermad med en frivillig jæger.',
					synopsis: require('./activities/visit-by-a-hunter/synopsis.md').default,
					descriptionHeader: require('./activities/visit-by-a-hunter/description-header.md').default,
					descriptionTalkAbout: require('./activities/visit-by-a-hunter/description-talk-about.md').default,
					descriptionFacts: require('./activities/visit-by-a-hunter/description-facts.md').default,
					descriptionFocus: require('./activities/visit-by-a-hunter/description-focus.md').default,
					descriptionReasoning: require('./activities/visit-by-a-hunter/description-reasoning.md').default,
					materials: require('./activities/visit-by-a-hunter/materials.md').default,
					illustration: 'lav-jaeger-stenaldermad.png',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null
				},
				{
					id: 'party',
					title: 'Fest på bopladsen',
					description: 'Vis forældrene jeres boplads, danse og tromme osv.',
					synopsis: require('./activities/party/synopsis.md').default,
					descriptionHeader: require('./activities/party/description-header.md').default,
					descriptionTalkAbout: require('./activities/party/description-talk-about.md').default,
					descriptionFacts: require('./activities/party/description-facts.md').default,
					descriptionFocus: require('./activities/party/description-focus.md').default,
					descriptionReasoning: require('./activities/party/description-reasoning.md').default,
					materials: require('./activities/party/materials.md').default,
					illustration: '',
					storyFile: null,
					storyNumber: null,
					storyTitle: null,
					storyDescription: null,
					storyAudio: null,
					badgeId: null
				}
			]
		},
	]}
];



export default chapterData;