import React from 'react';
import PropTypes from 'prop-types';
import BackButton from 'components/layout/back-button';
import ActivityPopup from './activity-popup';
import {generalUiTexts} from 'data/ui-texts';
import popupInfoText from 'data/pages/info.md';
import './chapter-configure.scss';

const ChapterConfigure = (props) => {
	let {
		isInStandaloneMode,
		animateMaterialsIcon,
		chapterIndex,
		chapter, 
		selectedActivityIds,
		selectedActivity,
		openPopup,
		selectPage, 
		handleShowPWAPopup,
		handleAnimateMaterialsIcon,
		toggleActivityDescription, 
		toggleActivityStory,
		toggleActivity,
		toggleActivityPopup
	} = props;


	let partHeader = [generalUiTexts.before, generalUiTexts.during, generalUiTexts.after];


	let popupTextLinks = [];
	if (!isInStandaloneMode) popupTextLinks = [{id: 'pwaPopup', action: handleShowPWAPopup, actionParams: []}];

	return (
		<div className="ChapterConfigure">
			{/* Back button */}
			<BackButton onClick={() => {selectPage('chapterOverview');}}/>

			{/* Page header */}
			<div className="ChapterConfigure-header">
				{/* Chapter menu */}
				<div className="ChapterConfigure-chapterMenu">
					{[...Array(3)].map((_, index) => {
						return (
							<div 
								key={index} 
								className={'ChapterConfigure-chapterItem ChapterConfigure-chapterItem--' + (index + 1) +
									(chapterIndex === index ? ' ChapterConfigure-chapterItem--selected' : '')}
								onClick = {() => {selectPage('chapterConfigure', index);}}
							/>
						);
					})}
				</div>

				{/* Material list link */}
				<div className="ChapterConfigure-materials"
					onClick = {() => {selectPage('materialList', chapterIndex);}}>
					<div className={'ChapterConfigure-materialsIcon' + (animateMaterialsIcon ? ' animate' : '')}></div>
					<div className="ChapterConfigure-materialsText">{generalUiTexts.materiallist}</div>
				</div>

				{/* Info popup button */}
				<div 
					className="ChapterConfigure-infoIcon"
					onClick = {() => {openPopup('infoPopup', null, popupInfoText, popupTextLinks, null, true);}}
				/>
			</div>

			{/* Page body */}
			<div className="ChapterConfigure-body">
				{chapter.themes.map((theme, themeIndex) => {
					return (
						<div key={themeIndex} className="ChapterConfigure-part">	
							<div className="ChapterConfigure-partHeader">{partHeader[themeIndex]}</div>
							<div className="ChapterConfigure-partBody">
								{theme.activities.map((activity, activityIndex) => {
									let isSelected = selectedActivityIds.indexOf(activity.id) >= 0;
									return ( 
										<div 
											key={activityIndex} 
											className={'ChapterConfigure-activity' + 
												(isSelected ? ' ChapterConfigure-activity--selected' : '') + 
												(activity.storyFile ? ' ChapterConfigure-activity--story' : '')
											}
											onClick={() => {toggleActivityPopup(activity.id);}}
										>
											<div className="ChapterConfigure-activityTitle">
												{activity.title}
											</div>
											<div className={'ChapterConfigure-activityDescription'}>
												{activity.description}
											</div>
											<div 
												className={'ChapterConfigure-activityStatus' + (isSelected 
													? ' ChapterConfigure-activityStatus--selected' 
													: '')
												}
												onClick={(event) => {
													event.stopPropagation(); 
													handleAnimateMaterialsIcon(!isSelected);
													toggleActivity(activity.id);
												}}
											/>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>

			<ActivityPopup 
				isOpen={selectedActivity !== null}
				activity={selectedActivity}
				selectedActivityIds={selectedActivityIds}
				toggleActivity={toggleActivity}
				handleAnimateMaterialsIcon={handleAnimateMaterialsIcon}
				toggleActivityDescription={toggleActivityDescription}
				toggleActivityStory = {toggleActivityStory}
				closePopup={toggleActivityPopup}
			/>
		</div>
	);
};

ChapterConfigure.propTypes = {
	isInStandaloneMode: PropTypes.bool.isRequired,
	animateMaterialsIcon: PropTypes.bool.isRequired,
	selectPage: PropTypes.func.isRequired,
	chapter: PropTypes.object.isRequired,
	selectedActivityIds: PropTypes.array.isRequired,
	selectedActivity: PropTypes.object,
	handleShowPWAPopup: PropTypes.func.isRequired,
	handleAnimateMaterialsIcon: PropTypes.func.isRequired,
	toggleActivity: PropTypes.func.isRequired,
	toggleActivityDescription: PropTypes.func.isRequired,
	toggleActivityStory: PropTypes.func.isRequired,
	chapterIndex: PropTypes.number.isRequired,
	openPopup: PropTypes.func.isRequired,
	toggleActivityPopup: PropTypes.func.isRequired
};

export default ChapterConfigure;