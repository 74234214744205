import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {setCookie, getCookie, deleteCookie} from 'helpers/cookie-helper';
import {isPhoneOrTablet, getOSAndBrowserInfo, isOldIOSDevice, getAndroidVersion} from 'helpers/device-helper';
import imagesData from 'data/images-data';
import ImageLoader from 'components/image-loader/image-loader';
import LandingPage from 'components/landing-page/landing-page';
import Loading from 'components/loading/loading';
import ChapterOverview from 'components/chapter-overview/chapter-overview';
import Introduction from 'components/introduction/introduction';
import StoryController from 'components/story/story-controller';
import Resources from 'components/resources/resources';
import About from 'components/about/about';
import ChapterConfigureController from 'components/chapter-configure/chapter-configure-controller';
import BadgesController from 'components/badges/badges-controller';
import MaterialList from 'components/material-list/material-list';

/**
 * Game pages object
 */
const gamePages = {
	landingPage: {component: LandingPage},
	chapterOverview: {component: ChapterOverview},
	introduction: {component: Introduction},
	story: {component: StoryController},
	resources: {component: Resources},
	about: {component: About},
	chapterConfigure: {component: ChapterConfigureController},
	badges: {component: BadgesController},
	materialList: {component: MaterialList}
};

class GameController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isPreloading: false,
			preloadImages: false,
			limitAnimations: true,
			introSeen: false,
			badgesIntroSeen: false,
			page: 'landingPage',
			prevPage: null,
			prevChapterIndex: null,
			chapterIndex: 0,
			selectedActivityIds: [],
			selectedBadgeIds: []
		};
		this.imagesPreloadedSuccessCount = 0;
		this.imagesPreloadedErrorCount = 0;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Preload images */		
		this.setState({isPreloading: true, preloadImages: true});

		/* Check if device is too old for all animations */
		let limitAnimations = true;

		if (!isPhoneOrTablet()) {
			limitAnimations = false;
		} else {
			let [os, ] = getOSAndBrowserInfo();
			let isIOS = (os === 'iOS' || os === 'Mac OS');	
			if (isIOS) {
				if ('serviceWorker' in navigator) limitAnimations = false;
			} else {
				if (getAndroidVersion() >= 8) limitAnimations = false;
			}
		}


		/* Get game progress from cache */
		let selectedBadgeIds = [];
		let selectedActivityIds = [];
		let introSeen = false;
		let badgesIntroSeen = false;

		this.loadUserDataCache().then((response) => {
			if (response.status === 'ok') {
				if (response.hasOwnProperty('introSeen') && response.introSeen !== null) {
					introSeen = response.introSeen;
				}
				if (response.hasOwnProperty('badgesIntroSeen') && response.badgesIntroSeen !== null) {
					badgesIntroSeen = response.badgesIntroSeen;
				}
				if (
					response.hasOwnProperty('selectedActivityIds') && 
						typeof response.selectedActivityIds === 'object'
				) selectedActivityIds = response.selectedActivityIds;
				if (
					response.hasOwnProperty('selectedBadgeIds') && 
						typeof response.selectedBadgeIds === 'object'
				) selectedBadgeIds = response.selectedBadgeIds;
			}
			this.setState({
				isLoading: false, 
				limitAnimations: limitAnimations,
				introSeen: introSeen,
				badgesIntroSeen: badgesIntroSeen,
				selectedActivityIds: selectedActivityIds,
				selectedBadgeIds: selectedBadgeIds
			});
		}).catch((error) => {
			console.error(error);
			this.setState({isLoading: false});
		});
	};

	/**
	 * Load game progress from cache
	 */
	loadUserDataCache = () => {
		return new Promise((resolve) => {
			let introSeen = null;
			let badgesIntroSeen = null;
			let selectedActivityIds = [];
			let selectedBadgeIds = [];

			if ('serviceWorker' in navigator && appConfig.env !== 'development') {
				/* Use service worker */
				fetch(appConfig.gameProgressEndpoint).then((response) => {return response.json();}).then((data) => {
					if (data) {
						if (data.introSeen) introSeen = data.introSeen;
						if (data.badgesIntroSeen) badgesIntroSeen = data.badgesIntroSeen;
						if (data.selectedActivityIds) selectedActivityIds = data.selectedActivityIds;
						if (data.selectedBadgeIds) selectedBadgeIds = data.selectedBadgeIds;
					}
					resolve({
						status: 'ok',
						introSeen,
						badgesIntroSeen,
						selectedActivityIds,
						selectedBadgeIds
					});
				}, (error) => {resolve({status: 'error', error});});
			} else {
				/* Use standard cookie */
				let cookieContentString = getCookie(appConfig.cookieGameProgressName);
				if (cookieContentString.length > 0) {
					let cookieContentObj = JSON.parse(cookieContentString);
					if (cookieContentObj.hasOwnProperty('introSeen')) introSeen = cookieContentObj.introSeen;
					if (cookieContentObj.hasOwnProperty('badgesIntroSeen')) {
						badgesIntroSeen = cookieContentObj.badgesIntroSeen;
					}
					if (cookieContentObj.hasOwnProperty('selectedActivityIds')) {
						selectedActivityIds = cookieContentObj.selectedActivityIds;
					}
					if (cookieContentObj.hasOwnProperty('selectedBadgeIds')) {
						selectedBadgeIds = cookieContentObj.selectedBadgeIds;
					}
				}
				resolve({
					status: 'ok',
					introSeen,
					badgesIntroSeen,
					selectedActivityIds,
					selectedBadgeIds
				});
			}
		});
	};

	/**
	 * Handle preload image
	 */
	handlePreloadImage = (status) => {
		/* Increase success / error counters */
		if (status === 'ok') {
			this.imagesPreloadedSuccessCount = this.imagesPreloadedSuccessCount + 1;
		} else {
			this.imagesPreloadedErrorCount = this.imagesPreloadedErrorCount + 1;
		}

		/* Check if all images have been preloaded */
		if ((this.imagesPreloadedSuccessCount + this.imagesPreloadedErrorCount) >= imagesData.length) {
			this.setState({isPreloading: false});
		}
	};

	handleShowPWAPopup = () => {
		/* Already in app mode */
		if (this.props.isInStandaloneMode) return;

		/* Device is desktop */
		// if (!isPhoneOrTablet()) return;

		/* Get OS and browser info */
		let [os, browser] = getOSAndBrowserInfo();
		let isIOS = (os === 'iOS' || os === 'Mac OS');
		
		let popupText = null;
		let popupClass = 'pwa';
		if ('serviceWorker' in navigator) {
			/* Device is SW compatible */
			popupText = require('data/pwa/pwa-install-info.md');
			popupClass = 'pwa' + (isIOS ? 'IOS' : '');
		} else {
			if (isIOS) {
				/* iOS device */
				if (browser !== 'ios' && browser !== 'safari') {
					/* Using Chrome in iOS */	
					popupText = require('data/pwa/chrome-on-ios.md');
				} else {
					if (isOldIOSDevice()) {
						/* Old device */
						popupText = require('data/pwa/old-device.md');
					} else {
						/* Old iOS */
						popupText = require('data/pwa/old-ios.md');
					}
				}
			} else {
				/* Not iOS device */
				popupText = require('data/pwa/default.md');
			}
		}

		this.props.openPopup(popupClass, null, popupText.default, [], null, true);
	};

	/**
	 * Go to a specific game page
	 * @param {string} page  					id of page, corresponds to property in object gamePages
	 * @param {number} chapterIndex 	chapter index (optional)
	 */
	selectPage = (page, chapterIndex = null) => {
		let prevPage = this.state.page;
		let prevChapterIndex = this.state.chapterIndex;
		this.setState({
			prevPage: prevPage,
			prevChapterIndex: prevChapterIndex,
			page: page, 
			chapterIndex: chapterIndex
		});
	};

	/**
	 * Flag intro as seen & update cache
	 */
	handleIntroSeen = () => {
		this.setState({introSeen: true}, () => {
			this.updateGameProgress();
		});
	};

	/**
	 * Flag badges intro as seen & update cache
	 */
	handleBadgesIntroSeen = () => {
		this.setState({badgesIntroSeen: true}, () => {
			this.updateGameProgress();
		});
	};

	/**
	 * Activate/deactivate a badge & update cache
	 * @param {string} badgeId 
	 */
	toggleBadge = (badgeId) => {
		let selectedBadgeIds = JSON.parse(JSON.stringify(this.state.selectedBadgeIds));
		let badgeIndex = selectedBadgeIds.indexOf(badgeId);
		if (badgeIndex < 0) {
			selectedBadgeIds.push(badgeId);
		} else {
			selectedBadgeIds.splice(badgeIndex, 1);
		}
		this.setState({selectedBadgeIds}, () => {this.updateGameProgress();});
	};

	/**
	 * Select / deselect activity & update cache
	 * @param {string} activityId
	*/
	toggleActivity = (activityId) => {
		let selectedActivityIds = JSON.parse(JSON.stringify(this.state.selectedActivityIds));
		let activityIndex = selectedActivityIds.indexOf(activityId);
		if (activityIndex < 0) {
			selectedActivityIds.push(activityId);
		} else {
			selectedActivityIds.splice(activityIndex, 1);
		}
		this.setState({selectedActivityIds}, () => {this.updateGameProgress();});
	};


	/**
	 * Updates cache data
	 */
	updateGameProgress = () => {
		let introSeen = this.state.introSeen;
		let badgesIntroSeen = this.state.badgesIntroSeen;
		let selectedActivityIds = JSON.parse(JSON.stringify(this.state.selectedActivityIds));
		let selectedBadgeIds = JSON.parse(JSON.stringify(this.state.selectedBadgeIds));
		if ('serviceWorker' in navigator && appConfig.env !== 'development') {
			/* Use service worker */
			fetch(appConfig.gameProgressEndpoint, {
				method: 'POST', 
				body: JSON.stringify({introSeen, badgesIntroSeen, selectedActivityIds, selectedBadgeIds})
			});
		} else {
			/* Use standard cookie */
			let cookieContentObj = {introSeen, badgesIntroSeen, selectedActivityIds, selectedBadgeIds};
			let cookieContentString = JSON.stringify(cookieContentObj);
			setCookie(appConfig.cookieGameProgressName, cookieContentString, 365);
		}
	};

	/**
	 * Reset the cache
	 */
	resetCache = () => {
		if ('serviceWorker' in navigator && appConfig.env !== 'development') {
			/* Use service worker */
			fetch(appConfig.cookieConsentEndpoint, {method: 'POST', body: JSON.stringify({})});
			fetch(appConfig.gameProgressEndpoint, {method: 'POST', body: JSON.stringify({})});
		} else {
			/* Use standard cookie */
			deleteCookie(appConfig.cookieConsentName);
			deleteCookie(appConfig.cookieGameProgressName);
		}
		window.location.reload();
	};

	/**
	 * Render Component
	 */
	render = () => {
		/* Get game page component */				
		let Component = LandingPage;
		if (gamePages.hasOwnProperty(this.state.page)) Component = gamePages[this.state.page].component;

		/* Game is loading */
		if ((this.state.isLoading || this.state.isPreloading) && this.state.page !== 'landingPage') {
			Component = Loading;
		}

		return (
			<React.Fragment>
				<Component
					isLoading={this.state.isLoading}
					isInStandaloneMode={this.props.isInStandaloneMode}
					limitAnimations={this.state.limitAnimations}
					introSeen={this.state.introSeen}
					badgesIntroSeen={this.state.badgesIntroSeen}
					chapterIndex={this.state.chapterIndex}
					prevPage={this.state.prevPage}
					prevChapterIndex={this.state.prevChapterIndex}
					selectedActivityIds={this.state.selectedActivityIds}
					selectedBadgeIds={this.state.selectedBadgeIds}
					selectPage={this.selectPage}
					handleIntroSeen={this.handleIntroSeen}
					handleBadgesIntroSeen={this.handleBadgesIntroSeen}
					toggleActivity={this.toggleActivity}
					toggleBadge={this.toggleBadge}
					resetCache={this.resetCache}
					handleShowPWAPopup={this.handleShowPWAPopup}
					openPopup={this.props.openPopup}
					closePopup={this.props.closePopup}
					openPopupPrint={this.props.openPopupPrint}
				/>
				{this.state.preloadImages && <ImageLoader handlePreloadImage={this.handlePreloadImage} />}
			</React.Fragment>
		);
	};
}

GameController.propTypes = {
	isInStandaloneMode: PropTypes.bool.isRequired,
	openPopup: PropTypes.func,
	openPopupPrint: PropTypes.func,
	closePopup: PropTypes.func
};

export default GameController;