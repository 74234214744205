import React from 'react';
import PropTypes from 'prop-types';
import BackButton from 'components/layout/back-button';
import PrintButton from 'components/layout/print-button';
import {generalUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import {handlePrintMaterial} from 'helpers/print-helper';
import chapterData from 'data/chapter-data';
import './material-list.scss';

const MaterialList = ({selectedActivityIds, prevPage, prevChapterIndex, selectPage}) => {	


	return (
		<div className="MaterialList">
			{/* Back button */}
			<BackButton color="red" onClick={() => {selectPage(prevPage, prevChapterIndex);}}/>

			{/* Header */}
			<div className="MaterialList-header">
				<div className="MaterialList-title">{generalUiTexts.materiallist}</div>
				{selectedActivityIds.length > 0 && 
					<PrintButton 
						page="materialList" 
						onClick={() => {handlePrintMaterial(chapterData, selectedActivityIds);}} 
					/>
				}
			</div>

			{/* Body */}
			<div className="MaterialList-body">
				{chapterData.map((chapter, chapterIndex) => {
					let selectedActivities = [];
					chapter.themes.forEach((theme) => {
						theme.activities.forEach((activity) => {
							if (selectedActivityIds.indexOf(activity.id) >= 0) {
								selectedActivities.push(activity);
							}
						});
					});
					return (
						<div 
							key={chapterIndex} 
							className={'MaterialList-chapter MaterialList-chapter--' + chapterIndex}
						>
							{selectedActivities.length === 0 && 
									<div className="MaterialList-text">{generalUiTexts.noActivitiesSelected}</div>}
							{selectedActivities.map((activity, index) => {
								return (
									<div key ={index} className="MaterialList-activity">
										<div className="MaterialList-activityTitle">{activity.title}</div>
										<div className="MaterialList-list">{renderMarkdown(activity.materials)}</div>
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
		</div>
	);
};

MaterialList.propTypes = {
	selectedActivityIds: PropTypes.array.isRequired,
	prevPage: PropTypes.string.isRequired,
	prevChapterIndex: PropTypes.number.isRequired,
	selectPage: PropTypes.func.isRequired,
};

export default MaterialList;