import React from 'react';
import PropTypes from 'prop-types';
import AudioPlayer, {RHAP_UI} from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './audio.scss';

const Audio = ({audioType, audioFile}) => {
	if (!audioFile) return null;
		
	let audioUrl = require('../../assets/audio/' + audioFile);
	let playIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70"><path fill="#ffc744" d="M35,0A35,35,0,1,0,70,35,35,35,0,0,0,35,0ZM25.38,48.61V21.38L52.84,35Z"/></svg>;
	let pauseIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 70"><path fill="#ffc744" d="M35,0A35,35,0,1,0,70,35,35,35,0,0,0,35,0ZM29.6,48.61h-10V21.38h10Zm20.81,0h-10V21.38h10Z"/></svg>;
		
	return (
		<div className={'Audio Audio--' + audioType}>
			<AudioPlayer 
				src={audioUrl}
				preload="auto"
				crossOrigin="anonymous"
				showJumpControls={false}
				layout="horizontal-reverse"
				progressUpdateInterval={1000}
				customProgressBarSection={[RHAP_UI.PROGRESS_BAR, RHAP_UI.CURRENT_TIME, RHAP_UI.DURATION]}
				customIcons = {{play: playIcon, pause: pauseIcon}}
				customAdditionalControls = {[]}
				customVolumeControls = {[]}
			/>
		</div>
	);
};

Audio.propTypes = {
	audioType: PropTypes.string.isRequired,
	audioFile: PropTypes.string.isRequired
};

export default Audio;