import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {generalUiTexts, popUpUiTexts} from 'data/ui-texts';
import {handlePrintStories} from 'helpers/print-helper';
import BackButton from 'components/layout/back-button';
import Audio from 'components/audio/audio';
import PrintButton from 'components/layout/print-button';
import ZoomButton from 'components/layout/zoom-button';
import BuildHuts from './animations/build-huts';
import LightAFire from './animations/light-a-fire';
import FindAnimalTracks from './animations/find-animal-tracks';
import MakeAWeapon from './animations/make-a-weapon';
import MoveLikeAHunter from './animations/move-like-a-hunter';
import GatherMagic from './animations/gather-magic';
import MusicAndSpirits from './animations/music-and-spirits';
import DrawMagicSymbols from './animations/draw-magic-symbols';
import TasteStoneageFood from './animations/taste-stoneage-food';
import './story.scss';


const animations = {
	'build-huts': {component: BuildHuts},
	'light-a-fire': {component: LightAFire},
	'find-animal-tracks': {component: FindAnimalTracks},
	'make-a-weapon': {component: MakeAWeapon},
	'move-like-a-hunter': {component: MoveLikeAHunter},
	'gather-magic': {component: GatherMagic},
	'draw-magic-symbols': {component: DrawMagicSymbols},
	'music-and-spirits': {component: MusicAndSpirits},
	'taste-stoneage-food': {component: TasteStoneageFood}
};

const Story = (props) => {
	let {
		type,
		limitAnimations,
		showStoryAnimation,
		showAudio,
		containerWidth,
		containerHeight,
		chapterIndex,
		activity,
		allStories,
		toggleStory,
		toggleStoryAnimation,
		toggleAudio,
		openPopup
	} = props;

	let audioFile = activity.storyAudio;
	let audioType = (showStoryAnimation ? 'animation' : 'text');


	let AnimationComponent = null;
	if (showStoryAnimation && animations.hasOwnProperty(activity.id)) {
		AnimationComponent = animations[activity.id].component;
	}

	return (
		<div className={'Story' + (type  ? ' Story--' + type : '')}>
			{/* Header */}
			<div className="Story-header">
				<div className="Story-info">
					{generalUiTexts.part + ' ' + (chapterIndex + 1) + ', ' + 
					generalUiTexts.chapter + ' ' + activity.storyNumber}
				</div>
				<div className="Story-title">{activity.storyTitle}</div>
			</div>
			
			{/* Body */}
			<div className="Story-body">
				<div className={'Story-picture Story-picture--' + activity.id}>
					{toggleStoryAnimation && <ZoomButton onClick={() => {toggleStoryAnimation(true);}} />}
				</div>
				<div className="Story-text">{renderMarkdown(activity.storyFile)}</div>
			</div>

			{/* Print button */}
			<PrintButton 
				page="story" 
				onClick = {() => {
					openPopup(
						'printStories', null, null, [],
						[
							{
								class: 'printStory', 
								text: popUpUiTexts.printStory, 
								action: handlePrintStories,
								actionParams: [[{
									number: activity.storyNumber, 
									title: activity.storyTitle,
									text: activity.storyFile
								}]]
							},
							{
								class: 'printStories', 
								text: popUpUiTexts.printStories, 
								action: handlePrintStories, 
								actionParams: [allStories]
							}
						],
						true, 
						true,
						allStories
					);
				}}
			/>

			{/* Animation */}
			{AnimationComponent && 
				<div 
					className={'Story-animation Story-animation--' + activity.id} 
					onClick={() => {toggleAudio(!showAudio);}}
				>
					<AnimationComponent 
						limitAnimations={limitAnimations}
						containerWidth={containerWidth} 
						containerHeight={containerHeight}
					/>
				</div>
			}

			{/* Audio */}
			{(audioFile && audioFile.length > 0) && 
				<div className={'Story-audio Story-audio--' + audioType	+ (!showAudio ? ' Story-audio--hidden' : '')}>
					<Audio audioType={audioType} audioFile={audioFile} />
				</div>
			}

			

			{/* Back button */}
			<BackButton 
				color="red" 
				onClick={() => {
					if (showStoryAnimation) {
						toggleStoryAnimation(false);
					} else {
						toggleStory((type === 'configure' ? false : ''));
					}
				}}
			/>
		</div>
	);
};

Story.defaultProps = {
	type: null,
	showStoryAnimation: false,
	showAudio: true
};

Story.propTypes = {
	type: PropTypes.string,
	showStoryAnimation: PropTypes.bool,
	limitAnimations: PropTypes.bool.isRequired,
	showAudio: PropTypes.bool, 
	containerWidth: PropTypes.number.isRequired,
	containerHeight: PropTypes.number.isRequired,
	activity: PropTypes.object.isRequired,
	allStories: PropTypes.array.isRequired,
	chapterIndex: PropTypes.number.isRequired,	
	toggleStory: PropTypes.func.isRequired,
	toggleStoryAnimation: PropTypes.func,
	toggleAudio: PropTypes.func,
	openPopup: PropTypes.func.isRequired
};

export default Story;