import React from 'react';
import PropTypes from 'prop-types';
import chapterData from 'data/chapter-data';
import {generalUiTexts} from 'data/ui-texts';
import Badge from 'components/badges/badge';
import IntroductionPopup from './introduction-popup';
import './chapter-overview.scss';

const ChapterOverview = (props) => {
	let {
		introSeen, 
		isInStandaloneMode, 
		selectedBadgeIds, 
		handleShowPWAPopup, 
		selectPage, 
		handleIntroSeen
	} = props;

	return (
		<div className="ChapterOverview">
			{/* Animations */}
			<div className="ChapterOverview-plant ChapterOverview-plant--bottomRight" />
			<div className="ChapterOverview-plant ChapterOverview-plant--bottomLeft" />
			<div className="ChapterOverview-plant ChapterOverview-plant--topRight" />
			<div className="ChapterOverview-plant ChapterOverview-plant--topCenter" />
			
			{/* Menu */}
			<div className="ChapterOverview-menu"> 
				<div className="ChapterOverview-logo" />
				<ul>
					<li onClick={() => {selectPage('introduction');}}>{generalUiTexts.linkIntroduction}</li>
					<li onClick={() => {selectPage('story');}}>{generalUiTexts.linkStory}</li>
					<li onClick={() => {selectPage('resources');}}>{generalUiTexts.linkResources}</li>
				</ul>
			</div>

			{/* Open PWA btn */}
			{!isInStandaloneMode && 
				<div className="ChapterOverview-downloadBtn" onClick={() => {handleShowPWAPopup();}} />}

			{/* Chapters */}
			<div className="ChapterOverview-chapters">
				{chapterData.map((chapter, chapterIndex) => {
					return (
						<div 
							key={chapterIndex} 
							className={'ChapterOverview-chapter ChapterOverview-chapter--' + (chapterIndex + 1)}
							onClick={() => {selectPage('chapterConfigure', chapterIndex);}}
						>
							<div className="ChapterOverview-badges">
								{chapter.themes.map((theme) => {
									return theme.activities.map((activity) => {
										if (activity.hasOwnProperty('badgeId') && activity.badgeId !== null) {
											let isSelected = (selectedBadgeIds.indexOf(activity.badgeId) >= 0);
											return (
												<Badge 
													key={activity.badgeId} 
													type="overview"
													isSelected={isSelected} 
													badgeId={activity.badgeId} 
													toggleBadgesSelector={() => {selectPage('badges');}}
												/>
											);
										}
										return null;
									});
								})}
							</div>
						</div>
					);
				})}
			</div>

			{/* Link to about page */}
			<div className="ChapterOverview-aboutBtn" onClick={() => {selectPage('about');}} />	

			{/* Introduction popup */}
			<IntroductionPopup isOpen={!introSeen} closePopup={handleIntroSeen}/>
		</div>
	);
};

ChapterOverview.propTypes = {
	introSeen: PropTypes.bool.isRequired,
	isInStandaloneMode: PropTypes.bool.isRequired,
	selectedBadgeIds: PropTypes.array.isRequired,
	handleShowPWAPopup: PropTypes.func.isRequired,
	selectPage: PropTypes.func.isRequired,
	handleIntroSeen: PropTypes.func.isRequired,
};

export default ChapterOverview;