import React from 'react';
import PropTypes from 'prop-types';
import './badge-button.scss';

const BadgeButton = ({page, isSelected, badgeId, onClick}) => {
	return (
		<div 
			className={'BadgeButton BadgeButton--' + page} onClick={onClick}>
			<div className={'BadgeButton-icon BadgeButton-icon--' + badgeId + 
				(isSelected ? ' BadgeButton-icon--selected' : '')}
			/>
		</div>
	);
};

BadgeButton.defaultProptypes = {
	page: 'activityDescription'
};

BadgeButton.propTypes = {
	page: PropTypes.string,
	isSelected: PropTypes.bool.isRequired,
	badgeId: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired
};

export default BadgeButton;