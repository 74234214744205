import React from 'react';
import PropTypes from 'prop-types';
import './badge.scss';

const Badge = ({isSelected, animate, type, badgeId, toggleBadgesSelector, touchEnd}) => {
	let className = 'Badge Badge--' + badgeId;
	if (isSelected) className = className + ' Badge--selected';
	if (isSelected && animate) className = className + ' Badge--animate';
	if (type) className = className + ' Badge--' + type;
	return (
		<div className={className}
			onMouseDown={(e) => {e.preventDefault(); if (toggleBadgesSelector) toggleBadgesSelector(badgeId);}}
			onTouchStart={(e) => {e.preventDefault();if (toggleBadgesSelector) toggleBadgesSelector(badgeId);}}
			onMouseUp={(e) => {e.preventDefault(); if (touchEnd) touchEnd();}}
			onTouchEnd={(e) => {e.preventDefault(); if (touchEnd) touchEnd();}}
			
		/>
	);
};

Badge.defaultProps = {
	type: null,
	animate: false
};

Badge.propTypes = {
	isSelected: PropTypes.bool.isRequired,
	animate: PropTypes.bool,
	type: PropTypes.string,
	badgeId: PropTypes.string.isRequired,
	toggleBadgesSelector: PropTypes.func,
	touchEnd: PropTypes.func,
};

export default Badge;